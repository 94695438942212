.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f5f5f5;

  // FIXME: Fix height

  .language__items {
    // min-height: calc(100vh - 114px);
    height: 100%;
    padding: 16px;
  }

  .language__item {
    background-color: #fff;
    padding: 16px;
    border: 1px solid #f0f0f0;

    &:first-child {
      border-radius: 8px 8px 0 0;
    }

    &:last-child {
      border-radius: 0 0 8px 8px;
    }
  }
}

.button {
  background-color: #f5f5f5;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.05);
  padding: 8px 16px 16px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.language__description {
  //  FIXME: Fix align
  margin: 4px 0 0 12px;
  display: flex;
  align-items: center;

  text-align: end;
  gap: 16px;
}

@media screen and (min-width: 1024px) {
  .container {
    background-color: #fff;
    .language__items {
      margin-bottom: 32px;
      min-height: unset;
      height: unset;

      background-color: #fafafa;
      border-radius: 8px;
    }
  }

  .button {
    position: relative;
    background-color: inherit;
    width: fit-content;
    margin-left: auto;
    box-shadow: none;
  }
}

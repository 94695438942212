.invitations__description {
  padding: 16px;
  border-bottom: 8px solid #f5f5f5;

  .invitations__description__text {
    white-space: pre-line;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 16px;
  }

  .invitations__meta {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    gap: 16px;

    .invitations__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      padding: 8px;
      background-color: #fafafa;
      border-radius: 8px;
      height: 64px;
      width: 100%;

      border: 1px solid #f0f0f0;

      .invitations__count {
        color: #418dff;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        font-size: 16px;
      }

      .invitations__text {
        font-style: normal;
        font-size: 12px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .invitations__description {
    background-color: #fafafa;
    border-radius: 0 0 8px 8px;
    border-bottom: none;

    .invitations__description__text {
      margin-bottom: 32px;
    }

    .invitations__button__container {
      width: fit-content;
      margin: 0 auto 32px auto;
    }

    .invitations__meta {
      .invitations__info {
        background-color: #fff;
      }
    }
  }
}

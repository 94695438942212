.section {
  width: 100%;
  input[type='file'] {
    display: none;
  }
  .attach-pictures-button {
    padding: 8px 40px;

    height: 40px;

    margin-bottom: 16px;

    background: #f4f6ff;
    border: 1px solid #1f317e;
    border-radius: 40px;
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      img {
        width: 24px;
        height: 24px;
      }
      span {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #1f317e;
      }
    }
  }
  & > div:last-child {
    width: inherit;

    align-self: flex-start;

    :global(.slick-track) {
      width: unset !important;
      display: flex !important;
      :global(.slick-slide) {
        margin-right: 8px;
        width: unset !important;
      }
    }
    .thumbnail {
      position: relative;
      width: 80px;
      height: 80px;
      & > img {
        cursor: pointer;
        z-index: 10000;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    & > img {
      width: 80px;
      height: 80px;
    }
  }
}

.attach__filename {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 140%;
  margin-bottom: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;

  :first-child {
    margin-right: 8px;
  }

  img {
    cursor: pointer;
  }
}

@media screen and (min-width: 1024px) {
  .section {
    .attach-pictures-button {
      width: 240px;
    }
  }
}

.image-thumbnail {
  display: flex;
  align-items: center;
  gap: 16px;

  .image-plus {
    cursor: pointer;
  }
}

.ul {
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s linear, opacity 0.5s linear;

  position: absolute;
  top: 80px;
  display: flex;
  flex-direction: column;

  width: 248px;
  height: fit-content;

  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;

  &.ul-open {
    visibility: visible;
    opacity: 1;
  }

  a:first-child {
    li {
      border-radius: 8px 8px 0px 0px;
    }
  }
  a:last-child {
    li {
      border-radius: 0px 0px 8px 8px;
    }
  }
  li {
    cursor: pointer;
    display: flex;
    padding: 12px 24px;
    width: 248px;
    height: fit-content;
    background: #ffffff;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.85);
    &:hover {
      transition: all 0.2s ease-in;
      background: rgba(31, 49, 126, 0.4);
      color: #ffffff;
    }
  }
}

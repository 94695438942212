.div {
  width: 100vw;
  background: #f5f5f5;
  padding: 16px 0;

  .category-menu {
    padding-bottom: 24px;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;

      & > div {
        &.category-menu-active {
          background: #4267ff;
          & + span {
            font-weight: 700;
            color: rgba(0, 0, 0, 0.85);
          }
        }
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background: #ffffff;
        img {
          object-fit: cover;
          width: 28px;
          height: 28px;
        }
      }
      & > span {
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
        text-align: center;
        color: rgba(0, 0, 0, 0.45);
      }
    }
  }
}

@media screen and (min-width: 960px) {
  .div {
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 1024px) {
  .div {
    :global(.splide__track) {
      overflow: unset !important;
    }
  }
}

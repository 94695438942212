.container {
  padding: 16px;
  background-color: #f5f5f5;
  width: 100%;
  min-height: 100vh;
  height: 100%;
}

@media screen and (min-width: 1024px) {
  .container {
    min-height: unset;
    height: unset;
    background-color: #fafafa;
    border-radius: 8px;
  }
}

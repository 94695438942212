.section {
  display: flex;
  flex-direction: column;
  width: 100%;
  // flex: 1;
  align-items: stretch;

  .cart_delivery_container {
    padding: 16px 24px;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.65);
    background: #fafafa;
    display: flex;
    flex-direction: column;
    width: 100%;
    // gap: 8px;

    b {
      font-weight: 700;
      font-size: 16px;
      margin-left: 8px;
      text-align: right;
    }

    & > div > :first-child {
      flex: 1;
    }

    .cart_delivery_container_upper {
      display: flex;
      div {
        display: flex;
      }
    }
    .cart_delivery_container_lower {
      & > div:first-child {
        font-weight: 700;
      }
      display: flex;
      div {
        display: flex;
      }
      b {
        color: #da3730;
      }
      .cart_delivery_free {
        color: #418dff;
        width: auto;
      }
    }

    .cart_delivery_container_from {
      b {
        font-weight: 700;
        line-height: 23.68px;
      }
      flex: 1;
    }
    .cart_delivery_container_price {
      b {
        font-weight: 700;
      }
      & > div > div {
        width: 85px;
        text-align: end;
      }
      & > div > b {
        width: 60px;
        padding-left: 20px;
      }
      .cart_delivery_container_upper {
        display: flex;
      }
      .cart_delivery_container_lower {
        display: flex;
        line-height: 23.68px;
        b {
        }
      }
    }
  }

  .cart-list-delivery-container {
    padding: 16px 24px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);

    background: #fafafa;
    & > div:last-child > div {
      font-weight: 700;
      line-height: 23.68px;
      b {
        color: #da3730;
      }
      span {
        color: #418dff;
      }
    }
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;

      & > div:first-child {
        min-width: 100px;
      }

      .bold {
        font-weight: 700;
      }
    }
  }
  .cart-mobile-total-count-price {
    & > b {
      font-weight: 700;
      font-size: 18px;
    }
  }
  .cart-list-select-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    margin: 0 24px;

    // border-bottom: 1px solid #f5f5f5;

    & > div:last-child {
      cursor: pointer;
      & > span {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
}

.checkout-guide-info {
  font-size: 14px;
  line-height: 20.72px;
  color: rgba(0, 0, 0, 0.65);
  & > b {
    color: #da3730;
    font-weight: 700;
    line-height: 19.6px;
  }
}

.cart-desktop-container {
  .cart-desktop__left-container {
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 12px;
  }
  @media screen and (min-width: 1024px) {
    margin-top: 30px;
    align-self: center;
    display: flex;
    width: 100%;
    // padding: 0 20px;
    .cart-desktop__left-container {
      flex: 66.6%;
      border-right: 1px solid #f0f0f0;
      border-bottom: none;
      margin-bottom: 0;

      .cart-list-select-container {
        margin: 0;
        padding-right: 24px;
      }
    }
    .cart-desktop__right-container {
      flex: 33.3%;
      display: flex;
      flex-direction: column;
      padding-bottom: 16px;

      h3 {
        padding: 16px;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        color: rgba(0, 0, 0, 0.85);
      }
      .cart-desktop-total-count-price {
        padding: 16px;
        margin-bottom: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #f0f0f0;
        span {
          font-size: 16px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.85);
        }
        b {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: #da3730;
        }
      }
      & > div:last-child {
        align-self: center;
        padding: 0 16px;
        width: 100%;
        & > div {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px 80px;
          height: 48px;
          background: #1f317e;
          border-radius: 40px;

          span {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-transform: uppercase;
            color: #ffffff;
          }
        }
      }
    }
  }
}

.cart-desktop-unavailable-container {
  display: flex;
  flex-direction: row;
  & > div:first-child {
    width: 100vw;
    // padding:16px;
    .cart-desktop-unavailable__header {
      padding: 16px 16px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      h3 {
        font-weight: 700;
        font-size: 16px;
        line-height: 30px;
        color: rgba(0, 0, 0, 0.85);
      }
      span {
        font-size: 14px;
        line-height: 140%;
        color: rgba(0, 0, 0, 0.65);
        cursor: pointer;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .section {
    align-items: center;
  }
  .cart-desktop-unavailable-container {
    width: 100%;
    padding: 0 20px;

    & > div:first-child {
      width: 100vw;
      padding: 32px 24px 32px 0;
    }

    & > div:first-child {
      padding: 32px 0;
      & > .cart-desktop-unavailable__header {
        padding: 16px 24px 0 0;
        h3 {
          font-size: 20px;
        }
      }
    }

    & > div:first-child {
      width: 66.6%;
    }
    & > div:last-child {
      width: 33.3%;
    }
  }
}

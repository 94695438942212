.container {
  width: 100%;
  justify-content: center;
  display: flex;
}

@media screen and (min-width: 1024px) {
  .container {
    width: 100%;
    background: #fafafa;
    border-radius: 8px;
    padding: 16px;
    border-bottom: none;
    display: block;
  }
}

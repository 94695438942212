.container {
  margin-top: 8px;
  width: 100%;

  .textarea {
    font-family: inherit;
    resize: none;
    width: inherit;
    height: 232px;

    padding: 16px;
    border: 1px solid #f0f0f0;
    border-radius: 8px;

    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.85);
    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: rgba(0, 0, 0, 0.25);
    }
    &:focus {
      outline: none;
      border: 1px solid #1f317e;
    }
  }
}

.container {
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.request__title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}

.request__upload {
  margin-bottom: 16px;
}

.request__button {
  margin: 0 16px 16px 16px;
}

@media screen and (min-width: 1024px) {
  .container {
    background-color: #fafafa;
    border-radius: 8px;
    margin-bottom: 32px;
  }

  .request__button {
    width: 210px;
    margin: 0 0 0 auto;
  }
}

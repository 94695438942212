.modal__header {
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.85);
  }

  img {
    cursor: pointer;
  }
}

.modal__description {
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: center;

  .modal__logo {
    margin-bottom: 16px;
  }

  .modal__text {
    font-size: 13px;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 4px;
  }

  .modal__subtext {
    font-size: 12px;
    text-align: center;
    color: rgba(0, 0, 0, 0.45);
  }
}

.modal__langs__container {
  padding: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  border-bottom: 8px solid #f5f5f5;
  padding-bottom: 16px;

  .modal__langs {
    padding: 8px 16px;
    font-size: 14px;

    color: rgba(0, 0, 0, 0.65);
    background-color: #f5f5f5;
    border-radius: 20px;

    cursor: pointer;
  }

  .modal__langs--selected {
    color: #ffffff;
    background-color: #1f317e;
  }
}

.modal__links__container {
  padding: 16px;
  display: flex;
  flex-direction: column;

  .modal__message {
    margin-bottom: 28px;
    font-size: 13px;
    text-align: center;
    color: rgba(0, 0, 0, 0.65);

    span {
      color: #418dff;
    }
  }

  .modal__links {
    display: flex;
    justify-content: space-evenly;

    .link__container {
      width: 64px;
      height: 64px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      .link__image {
        margin-bottom: 4px;
      }

      .link__description {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
}

@import '../../../../styles/globals';
.guide-info-container {
  height: 37px;
  display: flex;
  justify-content: center;
  padding: 8px 20px;
  width: 100vw;
  max-width: none !important;
  min-width: auto !important;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 8px 0px;
    width: 100%;

    & > .link-container {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: right;
      color: #418dff;
      padding-right: 10px;
    }
  }
}
@media screen and (min-width: 1024px) {
  .guide-info-container {
    padding: 0 !important;
    & > div {
      @include desktop_width;
    }
  }
}

.description {
  width: 100%;
  padding: 16px;
  display: none;

  .description-title {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: solid 1px #f0f0f0;
  }

  .description-items {
    width: 100%;

    .description-item {
      display: flex;
      flex-direction: row;
      margin-bottom: 16px;
      align-items: center;

      .description-number {
        width: 16px;
        height: 16px;
        text-align: center;
        color: #fff;
        font-size: 11px;
        line-height: 16px;
        background-color: #1f317e;
        border-radius: 50%;
        margin-right: 8px;
      }

      .description-content {
        font-size: 13px;
        line-height: 140%;
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
}

.description-alert {
  width: 100%;
  font-size: 13px;
  line-height: 140%;
  color: #da3730;
}

.toggle {
  width: 48px;
  height: 28px;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  border-radius: 14px;

  cursor: pointer;

  .toggle--circle {
    margin: 2px;
    width: 22px;
    height: 22px;
    background: #ffffff;
    box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    transition: transform ease 0.2s;
  }
}

.toggle-active {
  background: #1f317e;

  .toggle--circle {
    transform: translate(20px, 0);
  }
}

.container {
  margin-bottom: 16px;
  cursor: pointer;
}

.request__date {
  background-color: #fafafa;
  padding: 12px 16px;
  font-size: 13px;
  line-height: 140%;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #f0f0f0;
  border-bottom: none;
  border-radius: 8px 8px 0 0;
}

.request__content {
  padding: 16px;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.85);
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  border: 1px solid #f0f0f0;
  border-radius: 0 0 8px 8px;

  p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

@media screen and (min-width: 1024px) {
  .request__date {
    background-color: #eeeff2;
  }
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  width: 100%;

  .main {
    flex: 1;
    background: #fafafa;
    border-radius: 8px;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    min-height: auto;
    height: auto;
    margin-bottom: 48px;
  }
  .my-orders__desktop-tab {
    width: 100%;
    display: flex;
    gap: 16px;
    user-select: none;
    background-color: #ffffff;
  }
}

.pagination-margin {
  margin-bottom: 64px;
}

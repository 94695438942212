.container {
  padding: 16px;
  background-color: #fff;
  // min-height: calc(100% - 47px);
  flex: 1;
}

.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}

.request {
  padding: 16px;

  .request__title {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 12px;
  }

  .request__content {
    font-size: 12px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 16px;
  }

  .image__container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-bottom: 8px;
    flex-wrap: wrap;

    img {
      width: 80px;
      height: 80px;
    }
  }

  .date {
    font-size: 12px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
  }
}

.answer {
  background-color: #f2f5ff;
  padding: 16px;

  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 8px;
  }

  p {
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.65);
  }
}

@media screen and (min-width: 1024px) {
  .container {
    background-color: #fafafa;
    border-radius: 8px;
  }

  .request {
    background-color: #fff;
    margin-bottom: 16px;
    border-radius: 8px;
  }

  .answer {
    border-radius: 8px;
  }
}

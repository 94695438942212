.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  border-radius: 8px;
  height: calc(100vh - 64px);
  img {
    width: 140px;
    height: 100px;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    height: 568px;
  }
}

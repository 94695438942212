.div-plus {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7.83673px;
  gap: 10.45px;
  width: 40px;
  height: 40px;
  background: #1f317e;
  border-radius: 39.1837px;
  position: absolute;
  bottom: 6px;
  right: 6px;

  &.hide {
    display: none;
  }

  & > img {
    width: 27.43px;
    height: 27.43px;
  }

  & > span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
  }
  @media screen and (max-width: 1023px) {
    padding: 6.85714px;
    gap: 9.14px;
    width: 35px;
    height: 35px;
    border-radius: 34.2857px;
    img {
      width: 24px;
      height: 24px;
    }
  }
}

.div-plus-detail {
  z-index: 100;
  width: 124px;
  height: 40px;
  background-color: white;
  border-radius: 34.2857px;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.2));
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  bottom: 16px;

  .trash-icon {
    width: 20px;
    height: 20px;
  }
  .minus-blue-icon,
  .plus-blue-icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }
}

.notify-top-container {
  z-index: 10000;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 24px;
  .notify-container {
    margin: 0 auto;
    text-align: center;
    background: #f4f6ff;
    border: 1px solid #acb6dd;
    border-radius: 37px;

    padding: 7px 12px;
    width: 88px;
    height: 30px;

    span {
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #1f317e;
    }
  }
}

.wishlist_notify-container {
  bottom: -20px;
  width: 160px;
  left: auto;
  right: -5px;
  @media screen and (max-width: 1023px) {
    width: auto;
    bottom: 0;
    right: 0;
  }
}

.container__bg {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  padding: 16px;
  gap: 16px;
}

@media screen and (min-width: 1024px) {
  .container__bg {
    min-height: unset;
    height: unset;

    background-color: #fafafa;
    border-radius: 8px;
  }
}

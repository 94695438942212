.spin-wheel--container {
  cursor: pointer;
  z-index: 2147483650;
  padding: 16px 8px;
  transform: scale(0.9);
  width: 40px;
  height: 200px;
  position: fixed;
  right: -1.5px;
  top: 43%;
  background: #666e8f;
  border-radius: 4px 0px 0px 4px;
  .spin-wheel__inner-container {
    height: 148px;
    display: flex;
    flex-direction: column;
    .spin-wheel--container__wheel {
      @-moz-keyframes spin {
        100% {
          -moz-transform: rotate(360deg);
        }
      }
      @-webkit-keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
        }
      }
      @keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
      -webkit-animation: spin 4s linear infinite;
      -moz-animation: spin 4s linear infinite;
      animation: spin 4s linear infinite;
      object-fit: cover;
      width: 24px;
      height: 24px;
    }
    .spin-wheel--container__text {
      position: relative;
      width: 120px;
      height: 18px;
      top: 58px;
      right: 48px;

      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 140%;
      text-transform: uppercase;
      color: white;
      transform: rotate(90deg);
    }

    .modal__close {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 16px;
      margin: 0 auto;
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
  }
}

.modal-background {
  z-index: 2147483675;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-contents {
  position: relative;
  display: flex;
  align-items: center;
  gap: 72px;
  .modal-roulette {
    width: 400px;
    height: 400px;
    position: relative;
    display: flex;
    justify-content: center;
    .modal-roulette-plate {
      width: 400px;
      height: 400px;
    }
    .modal-roulette-pin {
      z-index: 5;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 40px;
      height: 56px;
    }
  }
  form {
    width: 320px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    h1 {
      font-weight: 700;
      font-size: 36px;
      line-height: 140%;
      color: #fff;
    }
    label {
      font-weight: 700;
      font-size: 14px;
      line-height: 140%;
      color: rgba(255, 255, 255, 0.8);
      margin-top: -8px;
    }
    .modal-form-button {
      position: relative;
      .button-extra-styles {
        z-index: -1;
        position: absolute;
        top: 4px;
        width: 100%;
        height: 48px;
        padding: 8px 80px;
        border-radius: 40px;
        background-color: #fff;
      }
    }
    ul {
      list-style-type: disc;
      padding-left: 20px;
      li {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: rgba(255, 255, 255, 0.45);
      }
    }
    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: rgba(255, 255, 255, 0.45);
    }
  }
  .modal__close {
    position: absolute;
    top: 0;
    right: -48px;
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
  @media screen and (max-height: 700px) {
    transform: scale(0.9);
  }
}

@media screen and (max-width: 900px) {
  .spin-wheel--container {
    top: 35vh;
  }
  .modal-contents {
    flex-direction: column;
    gap: 8px;
    .modal-roulette {
      width: 320px;
      height: 320px;
      .modal-roulette-plate {
        width: 320px;
        height: 320px;
      }
      .modal-roulette-pin {
        width: 32px;
        height: 44.8px;
      }
    }
    form {
      h1 {
        font-size: 22px;
      }
      ul {
        li {
          font-size: 11px;
          line-height: 16px;
        }
      }
      p {
        font-size: 11px;
        line-height: 16px;
      }
    }
    .modal__close {
      right: -16px;
    }
  }
}

.modal__outter {
  z-index: 2147483700;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
}

.modal-popup__inner {
  z-index: 2147483750;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 350px;
  background-color: #fff;
  padding: 16px 24px;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // gap: 24px;

  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

.modal__header {
  position: relative;
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  & > span {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
  }
  & > img {
    position: absolute;
    right: 0;
  }
}

.modal__description {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.65);
  white-space: pre-line;
  text-align: center;
  line-height: 140%;
  margin: 24px 0;
}

.modal__button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.modal__button {
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  background-color: #1f317e;
  border-radius: 40px;
  padding: 12px 40px;
  user-select: none;
  cursor: pointer;
}

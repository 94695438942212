.main {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  .top-banner-image {
    margin-top: 16px;
    width: 100%;
    max-width: 1120px;
    height: 390px;
  }
}

@media screen and (max-width: 1023px) {
  .main {
    .top-banner-image {
      padding: 0 16px;
      height: 240px;
    }
  }
}

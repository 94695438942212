.container {
  width: 95%;
  padding: 16px;
  margin-top: 16px;
  color: #a90700;
  line-height: 24px;
  background-color: #fff3f2;
  border: 1px solid #a90700;
  border-radius: 8px;
  text-align: center;
  position: absolute;
  top: 250px;
  right: 0;
  left: 0;
  // margin 0 auto;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
}

@media screen and (min-width: 1024px) {
  .container {
    position: absolute;
    max-width: 400px;
    top: 310px;
  }
}

.star-rating-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 16px;

  .star {
    cursor: pointer;
    width: 32px;
    height: 30px;
  }
}

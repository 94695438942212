.div {
  :global(.splide__arrow) {
    width: 40px;
    height: 40px;
    background: rgba(76, 76, 76, 0.2);
  }
  :global(.splide__arrow--prev) {
    left: -56px;
  }
  :global(.splide__arrow--next) {
    right: -46px;
  }
  :global(.splide__slide__row) {
    height: unset !important;
  }
}

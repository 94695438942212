.modal__outter {
  z-index: 2147483700;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
}

.modal__inner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 375px;
  background-color: #fff;
  padding: 16px 24px;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // gap: 24px;

  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

.modal__header {
  position: relative;
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  & > span {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
  }
  & > img {
    position: absolute;
    right: 0;
  }
}

.modal__description {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.65);
  white-space: pre-line;
  text-align: center;
  line-height: 140%;
  margin: 24px 0;
}

.modal__button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.modal__button {
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  background-color: #1f317e;
  border-radius: 40px;
  padding: 12px 40px;
  user-select: none;

  &.Primary {
    color: #fff;
    background-color: #1f317e;
  }

  &.Secondary {
    border: 1px solid #1f317e;
    background-color: #ffffff;
    font-weight: 700;
    color: #1f317e;
  }

  cursor: pointer;
}

@media screen and (min-width: 1024px) {
  .modal__inner {
    min-height: 190px;
  }
}

.div {
  position: relative;
  cursor: pointer;
  width: 20px;
  height: 20px;

  .triangle {
    z-index: 9999;
    width: 0px;
    height: 0px;
    border-bottom: 16px solid #fff;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    position: absolute;
    bottom: -26px;
    left: 2.5px;
  }

  .popup {
    position: absolute;
    width: 170px;
    height: 112px;
    top: 38px;
    left: -12px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    background: #fff;
    border-radius: 8px;

    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

    li {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 16px;
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .separator {
      display: block;
      width: 100%;
      height: 1px;
      background-color: #f0f0f0;
    }
  }
}

@media screen and (min-width: 1024px) {
  .div {
    position: relative;
    .popup {
      left: unset;
      right: -14px;
    }
  }
}

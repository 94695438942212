.info-alarm--container {
  width: 100%;
  height: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  .info-alarm--container__text {
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
  }
  .info-alarm--container__close {
    position: absolute;
    cursor: pointer;
    right: 3%;
    object-fit: cover;
    width: 16px;
    height: 16px;
    @media screen and (min-width: 1024px) {
      right: 11%;
    }
  }
}

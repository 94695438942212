.container {
  background-color: #fff;
  position: relative;
  border-radius: 8px;
  width: 100%;
}

.container--closed {
  border: 1px solid #f0f0f0;
}

.container--opened {
  border: 1px solid #595959;
}

.input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

  font-size: 14px;
  line-height: 140%;
}

.input--unselected {
  color: rgba(0, 0, 0, 0.25);
}

.input--selected {
  color: rgba(0, 0, 0, 0.85);
}

.select__items {
  z-index: 999;
  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow: auto;

  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  > div {
    position: relative;
  }

  > div::before {
    content: '';
    position: absolute;
    left: 16px;
    bottom: 0;
    height: 1px;
    width: calc(100% - 32px);
    border-bottom: 1px solid #d9d9d9;
  }

  :last-child::before {
    border: none;
  }
}

.select__item {
  width: 100%;
  padding: 16px;
  font-size: 14px;
  line-height: 140%;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  @media screen and (max-width: 1023px) {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.select__item--checked {
  position: absolute;
  top: 50%;
  transform: translate(8px, -50%);
  @media screen and (max-width: 1023px) {
    position: unset;
    transform: unset;
  }
}

.select__img {
  transition: transform 0.5s ease;
}

.select__img--opened {
  transform: rotate(-180deg);
}

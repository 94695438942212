.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  margin-bottom: 32px;

  & > article {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .header {
      h3 {
        font-size: 14px;
        line-height: 140%;
        text-align: center;
        color: rgba(0, 0, 0, 0.65);
        margin-bottom: 24px;
      }
    }
    .textarea {
      display: flex;
      flex-direction: column;
      align-items: center;
      h5 {
        width: 100%;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        color: rgba(0, 0, 0, 0.85);
      }
      textarea {
        font-family: inherit;
        resize: none;
        padding: 16px;
        margin-top: 16px;
        width: 100%;
        height: 232px;
        background: #ffffff;
        border: 1px solid #f0f0f0;
        border-radius: 8px;
        font-size: 12px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.65);
        &::placeholder {
          font-size: 14px;
          line-height: 140%;
          color: rgba(0, 0, 0, 0.25);
        }
        &:focus {
          outline: none;
          border: 1px solid #1f317e;
        }
      }
    }
    .image__uploader {
      display: flex;
      flex-direction: column;
      align-items: center;

      input[type='file'] {
        display: none;
      }

      & > div:last-child {
        width: inherit;
        padding-left: 16px;
        align-self: flex-start;
        margin-top: 24px;
        :global(.slick-track) {
          width: unset !important;
          display: flex !important;
          :global(.slick-slide) {
            margin-right: 8px;
            width: unset !important;
          }
        }
        .thumbnail {
          position: relative;
          width: 80px;
          height: 80px;
          & > img {
            cursor: pointer;
            z-index: 10000;
            width: 24px;
            height: 24px;
            position: absolute;
            top: 0;
            right: 0;
          }
        }
        & > img {
          width: 80px;
          height: 80px;
        }
      }
    }
  }
}

.button {
  height: 48px;
  border-radius: 40px;

  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
  }
  &.active {
    background: #1f317e;
    span {
      color: #ffffff;
    }
  }
  &.disabled {
    cursor: not-allowed;
    background: #d9d9d9;
    span {
      color: #bfbfbf;
    }
  }
}

@media screen and (max-width: 1023px) {
  .button-container {
    position: fixed;
    bottom: 0;
    background: #ffffff;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.05);
    width: 100%;
    padding: 8px 16px 16px;
  }
}

@media screen and (min-width: 1024px) {
  .section {
    background-color: #fafafa;
  }

  .button {
    width: fit-content;
    padding: 12px 80px;
    margin: 0 0 0 auto;
  }
}

.section {
  color: rgba(0, 0, 0, 0.85);

  .title {
    font-weight: 700;
    line-height: 24px;
  }
  & > article {
    position: relative;
    cursor: pointer;
    margin: 16px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    width: 100%;
    background: #ffffff;
    border-radius: 8px;

    .arrow {
      position: absolute;
      right: 16px;
      // top: 16px;
      transition: transform 0.2s ease;
    }

    .accordion--opened {
      .arrow {
        transform: rotate(-180deg);
      }
    }
    .GREY {
      background: #dee1ec;
    }

    .content {
      p {
        font-size: 13px;
        line-height: 140%;
        color: rgba(0, 0, 0, 0.65);
      }

      b {
        display: block;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 8px;
      }

      span {
        display: block;
        font-size: 13px;
        line-height: 140%;
      }
    }

    & > img {
      width: 24px;
      height: 24px;
    }

    & > b {
      font-weight: 700;
      line-height: 24px;
      color: #da3730;
    }
  }
}

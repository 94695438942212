.container {
  display: flex;
  padding: 24px 0;
  align-items: center;

  .user {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .user__image__container {
    position: relative;
  }

  .user__image {
    width: 56px;
    height: 56px;
    border-radius: 28px;
    object-fit: cover;
  }

  .user__image__remove {
    position: absolute;
    top: 0;
    right: 0;
  }

  .user__information {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 0;
    height: 56px;

    .user__information__nickname {
      font-size: 14px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.85);
    }
    .user__information__id {
      font-size: 12px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.45);
    }
  }

  .user__button__edit {
    padding: 8px 40px;
    border: 1px solid #1f317e;
    border-radius: 40px;
    background: #f4f6ff;
    margin-bottom: 24px;

    img {
      width: 20px;
      height: 20px;
    }
    span {
      margin-left: 8px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #1f317e;
    }
  }
  .button--change-profile-photo {
    margin: 24px 0 0;

    padding: 8px 40px;
    border: 1px solid #1f317e;
    border-radius: 40px;
    background: #f4f6ff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    img {
      width: 20px;
      height: 20px;
    }
    span {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #1f317e;
    }
  }

  input {
    display: none;
  }
}

.container--edit,
.container--view {
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
}

@media screen and (min-width: 1024px) {
  .container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 24px;

    .user {
      flex-direction: row;
      gap: 16px;

      .user__image {
        width: 72px;
        height: 72px;
        border-radius: 36px;
        object-fit: cover;
      }

      .user__information {
        align-items: flex-start;
        .user__information__nickname {
          font-size: 16px;
        }
      }
    }

    .user__button__edit {
      margin-bottom: 0;
      padding: 8px 60px;

      img {
        width: 24px;
        height: 24px;
      }
      span {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .button--change-profile-photo {
      margin: 24px 0 0;
      padding: 8px 40px;

      img {
        width: 24px;
        height: 24px;
      }
      span {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .container--edit {
    flex-direction: column;
  }
}

.result {
  position: relative;
  width: 100%;
  padding-bottom: 96px;

  .result__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    background-color: #f5f5f5;

    .result__header__count {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);

      span {
        font-weight: 700;
        color: #418dff;
      }
    }
  }

  .select__container {
    position: absolute;
    top: 32px;
    right: 0;
    width: 300px;
    height: 32px;
  }
}

@import '../../styles/globals';
.mobile_container {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.popular {
  padding: 16px;

  .popular__title {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 8px;
  }

  .popular__items {
    display: flex;
    flex-direction: row;
    gap: 8px;
    flex-wrap: wrap;

    .popular__item {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      padding: 8px;
      border-radius: 8px;
      background-color: #f5f5f5;
      cursor: pointer;
    }
  }
}

.result {
  width: 100%;
  // padding-bottom: 96px;
  flex: 1;
  display: flex;
  flex-direction: column;

  .result__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    background-color: #f5f5f5;

    .result__header__count {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);

      span {
        font-weight: 700;
        color: #418dff;
      }
    }
  }
}

.product-container {
  padding-bottom: 150px;
}

.item {
  position: relative;
  padding: 16px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  border-bottom: 1px solid #f5f5f5;

  .thumnail {
    position: relative;
    width: 100px;
    height: 100px;
    border: 1px solid #f5f5f5;

    img {
      height: 100%;
    }

    .thumnail__soldout {
      width: inherit;
      height: inherit;
      top: 0;
      left: 0;
      position: absolute;
      bottom: 4px;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;

      font-weight: 700;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
    }

    .product-flag {
      position: absolute;
      top: 0;
      display: flex;
      width: 100%;
      height: 24px;

      .flag {
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 6px;
        width: 32px;
        height: 18px;
        margin-right: 2px;

        background-color: #da3730;

        &.best {
          background-color: #ff9835;
        }

        &.new {
          background-color: #2f4ccb;
        }

        span {
          font-size: 10px;
          line-height: 140%;
          font-weight: 700;
          color: #ffffff;
        }
      }
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 8px;

    .description__name {
      font-size: 14px;
      line-height: 140%;
      color: rgba(0, 0, 0, 0.85);
    }

    .description__history {
      font-size: 12px;
      line-height: 18px;
      color: #da3730;
      align-self: flex-start;
    }

    .description__price__current {
      font-weight: 700;
      line-height: 24px;
      color: #da3730;
    }

    .description__price--soldout {
      display: flex;
      flex-direction: column;
      &::before {
        content: 'In stock soon';
        font-size: 12px;
        line-height: 22px;
        color: #da3730;
      }
    }

    .description__price {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .current-price {
        margin-right: 4px;

        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #da3730;
      }

      .description__price__original {
        font-size: 12px;
        line-height: 22px;
        text-decoration-line: line-through;
        color: rgba(0, 0, 0, 0.45);
      }
    }

    .description__star {
      display: none;
    }

    .item__control {
      position: absolute;
      bottom: 21px;
      right: 16px;
      & div {
        position: initial;
      }
    }
  }
}

.empty_container {
  // height: 70vh;
  flex: 1;
  background-color: #fafafa;
}

@media screen and (min-width: 1024px) {
  .container {
    @include desktop_width;
    // width: 100%;
    // max-width: 1024px;
    max-width: none !important;
    padding: 0 !important;
    flex: 1;
  }

  .popular {
    padding: 32px 0;
    width: 100vw;
    background-color: #fff;
    border-bottom: 1px solid #f5f5f5;
    .popular__title {
      font-size: 16px;
    }
    & > div {
      max-width: 1160px;
      padding: 0 20px;
      margin: 0 auto;
    }
  }

  .products {
    display: flex;
    flex-direction: row;
    position: relative;
    max-width: 1160px;
    padding: 0 20px;
    margin: 0 auto;
    margin-bottom: 48px;

    & > :last-child {
      margin-top: 32px;
    }

    .select__container {
      position: absolute;
      top: 4px;
      right: 24px;
      width: 300px;
      height: 32px;
    }

    & > div {
      padding: 16px 0 0;
    }
  }
}

.section {
  width: 100%;
  padding: 16px;
  margin-top: 10px;

  .product__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;

    .product__vendor {
      cursor: pointer;
      line-height: 140%;
      color: rgba(0, 0, 0, 0.45);
    }

    .product__flags {
      display: flex;
      flex-direction: row;
      margin-bottom: 4px;
      gap: 3px;

      .product__flag {
        font-weight: 700;
        font-size: 13px;
        color: #ffffff;
        padding: 4px 8px;
      }

      .product__flag--best {
        background-color: #ff9835;
      }

      .product__flag--new {
        background-color: #2f4ccb;
      }
    }

    .product__control {
      position: absolute;
      right: 0;
      top: -10px;
      display: flex;
      gap: 16px;
    }
  }

  & > h4 {
    font-weight: 700;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
  }

  .product__description {
    margin-top: 8px;
    display: flex;
    flex-direction: column;

    .product__price {
      display: flex;
      align-items: center;
    }

    h2 {
      display: inline-block;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: #da3730;
      margin-right: 8px;
    }

    span {
      display: inline-block;
      padding: 4px 6px;
      font-size: 12.5px;
      background-color: #da3730;
      color: #fff;
    }

    h3 {
      margin-top: 8px;
      line-height: 140%;
      text-decoration-line: line-through;
      color: rgba(0, 0, 0, 0.45);
    }
  }
}

.product__buttons {
  display: none;
}

.product__related__categories {
  display: none;
}

@media screen and (min-width: 1024px) {
  .section {
    flex: 1;
    width: auto;
    border-bottom: none;
    margin-top: 0px;
    // .product__flags {
    //   display: flex;
    //   flex-direction: row;
    //   margin-bottom: 4px;

    //   .product__flag {
    //     font-weight: 700;
    //     font-size: 13px;
    //     color: #ffffff;
    //     padding: 4px 8px;
    //   }

    //   .product__flag--BEST {
    //     background-color: #ff9835;
    //   }

    //   .product__flag--NEW {
    //     background-color: #2f4ccb;
    //   }

    //   .product__flag--DISCOUNT {
    //     display: none;
    //   }
    // }

    .product__header {
      .product__control {
        & > :first-child {
          display: none;
        }
      }
    }

    .product__description {
      margin-bottom: 32px;
    }
  }

  .product__buttons {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-bottom: 32px;

    & > div:nth-child(2) {
      display: flex;
      flex: 1;

      & > div {
        width: 100%;
      }
    }

    .product__button--wish {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 8px;
      font-weight: 700;
      background-color: #f4f6ff;
      // width: 287px;
      height: 48px;
      user-select: none;
      flex: 1;
      border: 1px solid #1f317e;
      border-radius: 43px;
      color: #1f317e;

      cursor: pointer;
    }

    .product__button--wish:active {
      transform: translate(0, 2px);
    }
  }

  .product__related__categories {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    // max-width: 560px;

    .product__related__category {
      font-size: 14px;
      text-align: center;
      color: rgba(0, 0, 0, 0.45);

      border: 1px solid #d9d9d9;
      border-radius: 37px;
      padding: 8px 16px;

      cursor: pointer;
    }
  }
}

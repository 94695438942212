.div {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  height: 100%;
}

@media screen and (min-width: 1024px) {
  .div {
    min-height: auto;
    height: auto;
    margin-bottom: 48px;
  }
}

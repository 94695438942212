// Fonts

// (En.)

// (Ko.)

// Utilities
@import 'reset';
@import 'variables';
@import 'elements';
@import 'mixins';

// default
html,
body {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  line-height: 1;
  image-rendering: -webkit-optimize-contrast;
  font-family: 'Noto Sans KR';

  // input,
  // textarea {
  // }

  // input:focus,
  // textarea:focus {
  // }

  // input::placeholder,
  // textarea::placeholder,
  // input:-webkit-autofill::first-line,
  // input:-webkit-autofill,
  // input:-webkit-autofill:hover,
  // input:-webkit-autofill:focus,
  // input:-webkit-autofill:active {
  // }

  #root {
    height: 100%;
  }
}

.hide {
  display: none;
}

#app_main {
  height: 100%;
  display: flex;
  flex-direction: column;
}
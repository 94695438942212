.span {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.85);
}

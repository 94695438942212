.div {
  margin-bottom: 20px;
  .section {
    margin-top: 40px;
    .product-category-title {
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: rgba(0, 0, 0, 0.85);
      }
      a {
        display: flex;
        align-items: center;
        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          color: rgba(0, 0, 0, 0.45);
        }
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
    .product--infinite-scroll {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      gap: 16px 0;
      & > article {
        width: 164px;
      }
    }
    .product-custom-container {
      width: 100%;
      max-width: 1440px;
      display: grid;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 1023px) {
  .div {
    .section {
      padding: 0 16px;
      margin-top: 24px;
      .product-category-title {
        height: 32px;
        margin-bottom: 8px;
        h2 {
          font-size: 16px;
          line-height: 24px;
        }
        a {
          span {
            font-size: 13px;
            line-height: 140%;
          }
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  flex: 1;

  .header {
    width: 100%;
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #dee1ec;
    .header__title {
      display: none;
    }
    .header__date {
      font-size: 13px;
      line-height: 140%;
      color: rgba(0, 0, 0, 0.85);
    }

    .header__no {
      font-size: 13px;
      line-height: 140%;
      // border: 1px solid #f0f0f0;

      .header__number {
        display: flex;
        gap: 4px;
        align-items: center;
        :first-child {
          color: rgba(0, 0, 0, 0.65);
        }

        :last-child {
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }
  }

  .main {
    width: 100%;
    display: flex;
    flex: 1;

    flex-direction: column;
    padding: 16px;
    background-color: #f5f5f5;

    .payment__title {
      margin-bottom: 16px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.85);
    }

    section {
      display: flex;
      flex-direction: column;
      padding: 16px;
      background: #ffffff;
      border-radius: 8px;
      margin-bottom: 16px;
    }

    .payment {
      border: 1px solid #f0f0f0;
      .payment__border {
        width: 100%;
        height: 1px;
        background: #f5f5f5;
        margin-bottom: 16px;
      }
      .payment__container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 16px;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.85);
        width: 100%;

        &.payment__container--CANCELLED {
          opacity: 0.3;
        }

        &.payment__container--refund {
          h5,
          span {
            font-weight: 400;
            font-size: 13px;
            line-height: 140%;
            color: #da3730 !important;
          }
        }

        span {
          font-weight: 400;
          font-size: 13px;
          line-height: 140%;
          color: rgba(0, 0, 0, 0.85);
        }

        &:nth-child(4) {
          padding-bottom: 16px;
          border-bottom: 1px solid #f5f5f5;
          span {
            font-weight: 400;
            font-size: 13px;
            line-height: 140%;
            color: #da3730;
          }
        }

        &:last-child {
          margin-bottom: 0;
          h5,
          span {
            font-weight: 700;
          }
        }
      }
    }

    .delivery {
      border: 1px solid #f0f0f0;
      .delivery__name {
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
      }

      .delivery__address {
        margin-bottom: 8px;
        white-space: pre-line;
        line-height: 140%;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.85);
      }
      .delivery__email_phone {
        display: flex;
        gap: 4px;
      }

      .delivery__instruction {
        font-size: 13px;
        color: rgba(0, 0, 0, 0.85);
      }

      .delivery__instruction--none {
        font-size: 13px;
        color: rgba(0, 0, 0, 0.45);
      }
    }

    .information__products {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;

      .delivery__title {
        display: none;
      }

      &.information__products--SHIPPED {
        margin-bottom: 16px;
      }

      .information__arrive {
        display: flex;
        flex-direction: column;
        font-size: 13px;

        .information__arrive__message {
          white-space: pre-line;
          margin-bottom: 4px;
          line-height: 140%;
          font-weight: 700;
          color: rgba(0, 0, 0, 0.85);
        }
        .information__arrive__message--PENDING {
          color: #418dff;
        }
        .information__arrive__message--READY {
          color: #418dff;
        }
        .information__arrive__message--SHIPPING {
          color: #ff4d4f;
        }
        .information__arrive__message--CANCELLED {
          color: #da3730;
        }

        .information__arrive__date {
          color: rgba(0, 0, 0, 0.45);
        }
      }

      // .information__buttons {
      //   display: flex;
      //   flex-direction: row;
      //   gap: 4px;

      //   .information__button {
      //     cursor: pointer;
      //     border: 1px solid #acb6dd;
      //     font-size: 14px;
      //     border-radius: 37px;
      //     padding: 6px 12px;
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;
      //     height: 32px;
      //   }

      //   .information__button--secondary {
      //     background-color: #f4f6ff;
      //     font-weight: 700;
      //     color: #1f317e;
      //   }

      //   .information__button--primary {
      //     color: rgba(0, 0, 0, 0.65);
      //     background-color: #fff;
      //   }
      // }
    }
  }
}
.delivery__cancelled__title {
  display: block;
  font-size: 18px;
  line-height: 26.64px;
  font-weight: 700;
  margin: 24px 0;
  color: #da3730;
}
.information__buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 4px;
  margin: 16px 0;

  .information__button {
    cursor: pointer;
    border: 1px solid #acb6dd;
    font-size: 14px;
    border-radius: 37px;
    padding: 6px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
  }

  .information__button--secondary {
    background-color: #f4f6ff;
    // font-weight: 700;
    color: #1f317e;
  }

  .information__button--primary {
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
  }
}

.button__container {
  background-color: #f5f5f5;
  padding: 8px 16px 16px 16px;
  &.button__container--SHIPPED {
    background-color: #fff !important;
  }
  @media screen and (max-width: 1023px) {
    width: 100%;
    position: fixed;
    bottom: 0;
  }
}

.order__button {
  width: 100%;
  text-align: center;
  border-radius: 40px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
}

.order__button--primary {
  background: #1f317e;
  color: #ffffff;
}
.order__button--secondary {
  border: 1px solid #1f317e;
  font-weight: 700;
  color: #1f317e;
}

@media screen and (min-width: 1024px) {
  .container {
    background-color: #fafafa;
    padding: 16px;
    margin-bottom: 20px;
    border-radius: 8px;
    min-height: auto;
    flex: none;

    .header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      background-color: transparent;
      padding: 0;

      .header__title {
        display: block;
        font-size: 18px;
        line-height: 26.64px;
        font-weight: 700;
        margin-bottom: 8px;
      }

      .header__date {
        margin-bottom: 24px;
      }

      .header__no {
        width: 100%;
        padding: 12px 16px;
        background-color: #eeeff2;
        border-radius: 8px 8px 0px 0px;
        display: flex;
        justify-content: space-between;
      }

      .header__status {
        padding: 4px 12px;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;

        .header__status--PENDING,
        .header_-status--READY {
          color: #418dff;
        }

        .header__status--SHIPPING {
          color: #ff4d4f;
        }

        .header__status--SHIPPED,
        .header__status--CANCELLED {
          color: rgba(0, 0, 0, 0.45);
        }
      }
    }

    .main {
      padding: 0;
      background-color: transparent;
      .payment {
        border: none;
      }
      section {
        margin-bottom: 0;
      }
      .delivery {
        border: none;
      }

      .information {
        display: flex;
        flex-direction: row;
        background-color: #fff;
        border: 1px solid #f0f0f0;
        border-radius: 0px 0px 8px 8px;
        margin-bottom: 40px;

        .information__container {
          width: 100%;
        }

        > div:first-child {
          margin: 16px 0;
          padding: 0 16px;
          border-right: 1px solid #f5f5f5;
        }

        > div:last-child {
          padding: 16px;
        }
      }

      .information__products {
        margin-bottom: 24px;

        .delivery__title {
          display: block;
          font-size: 18px;
          line-height: 26.64px;
          font-weight: 700;
          margin-bottom: 8px;
        }

        .information__arrive {
          display: none;
          flex-direction: row;
          align-items: center;

          .infromation__arrive__message {
            white-space: unset;
            font-size: 18px;
            margin-right: 8px;
          }

          .infromation__arrive__date {
            color: rgba(0, 0, 0, 0.45);
          }
        }

        .information__buttons {
          margin: 0;
          .information__button {
            padding: 6px 60px;
            align-items: flex-end;
            font-weight: 400;
          }
        }
      }
    }
  }
  .button__container {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;
    padding: 8px 0;
  }

  .order__button {
    min-width: 270px;
    width: auto;
  }
}

.div {
  position: relative;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.nav-cart--notification-custom {
  width: auto;
  height: auto;
  top: -5px;
  left: 10px;
}

@import '../../../../styles/elements';

.form-button {
  width: 100%;
  height: 48px;

  cursor: pointer;
  padding: 8px 20px;
  color: #ffffff;
  background-color: #1f317e;
  border-radius: 40px;
  border: 0;

  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
  text-transform: uppercase;

  &.disabled {
    cursor: not-allowed;
    background: #f0f0f0;
    color: #bfbfbf;
    &:active {
      position: unset;
    }
  }

  &:active {
    @extend %button-active;
  }
}

@media screen and (min-width: 1024px) {
  .form-button {
    min-width: 200px;
  }
}

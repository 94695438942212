@import '../../styles/variables';

.section {
  position: relative;
  width: 100vw;

  .banner-container {
    width: 100%;

    & > img,
    div {
      border-radius: 8px;

      width: inherit;
      height: inherit;
    }

    .banner-numbering {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;

      background: rgba(136, 136, 136, 0.7);

      span {
        color: #fff;
      }
    }
  }

  .stick-blur {
    position: absolute;
    z-index: 10;
    top: 0;

    &:first-of-type {
      left: 0;
      background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    }

    &:last-of-type {
      right: 0;
      background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    }
  }

  &.banner-BANNER_ROLLING-styles {
    .banner-container {
      height: 200px;
    }
  }

  &.banner-BANNER_FIXING-styles {
    .banner-container {
      height: 94px;
      img {
        border-radius: 0;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .section {
    .banner-container {
      .banner-numbering {
        right: 16px;
        bottom: 16px;

        width: 33px;
        height: 22px;
        border-radius: 27px;

        span {
          font-size: 11px;
          line-height: 20px;
        }
      }
    }

    .stick-blur {
      width: 15px;
      height: 240px;
    }

    &.banner-TOP-styles {
      .banner-container {
        height: 240px;
      }
      :global(.slick-slide) {
        padding: 0 4px;

        &:first-child:nth-last-child(1) {
          padding: 0 16px;
        }
      }
    }

    &.banner-BANNER_ROLLING-styles {
      padding: 0px;

      :global(.slick-slide) {
        padding: 0 4px;

        &:first-child:nth-last-child(1) {
          padding: 0 16px;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .section {
    .banner-container {
      .banner-numbering {
        right: 32px;
        bottom: 32px;

        width: 54px;
        height: 36px;
        border-radius: 40px;

        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 32px;
        }
      }
    }

    .stick-blur {
      width: 87px;
      height: 390px;
    }

    &.banner-TOP-styles {
      .banner-container {
        height: 390px;
      }

      :global(.slick-slide) {
        padding: 0 20px;

        &:first-child:nth-last-child(1) {
          .banner-container {
            margin: 0 auto;
            max-width: 1100px;
          }
        }
      }

      :global(.slick-slider) > div:first-of-type {
        left: calc((100vw - 812px) / 2);
      }

      :global(.slick-slider) > div:last-of-type {
        right: calc((100vw - 812px) / 2);
      }

      :global(.slick-slider) > div:first-child:nth-last-child(1) {
        left: 0;
        right: 0;
      }
    }

    &.banner-BANNER_ROLLING-styles {
      max-width: 1124px;
      padding: 0px !important;

      :global(.slick-slide) {
        padding: 0 12px;
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .section {
    &.banner-TOP-styles {
      :global(.slick-slider) > div:first-of-type {
        left: calc((100vw - 1058px) / 2);
      }

      :global(.slick-slider) > div:last-of-type {
        right: calc((100vw - 1058px) / 2);
      }

      :global(.slick-slider) > div:first-child:nth-last-child(1) {
        left: 0;
        right: 0;
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  .section {
    &.banner-TOP-styles {
      :global(.slick-slider) > div:first-of-type {
        left: calc((100vw - 1076px) / 2);
      }

      :global(.slick-slider) > div:last-of-type {
        right: calc((100vw - 1076px) / 2);
      }

      :global(.slick-slider) > div:first-child:nth-last-child(1) {
        left: 0;
        right: 0;
      }
    }
  }
}

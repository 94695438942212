.section {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 16px 16px 34px;

  .slick__container {
    width: 320px;
    position: relative;
  }

  article {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 320px;
    width: 320px;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  :global(.slick-dots) {
    bottom: -40px;
    li {
      margin: 0;
    }
  }
}

.section__overlay {
  display: none;
}

.section__overlay--soldout {
  z-index: 12;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 1024px) {
  .section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0;

    .thumnail__images {
      display: flex;
      flex-direction: column;
      margin-right: 40px;
      gap: 4px;

      .thumnail__image {
        height: 64px;
        width: 64px;
        border: 1px solid #f0f0f0;
        cursor: pointer;

        img {
          height: 100%;
          width: 100%;
          padding: 8px;
          object-fit: contain;
        }
      }
      .thumnail__image__default {
        height: 64px;
        width: 64px;
        background-color: #f0f0f0;
      }
    }

    .thumnail__image--selected {
      position: relative;
      width: 352px;
      height: 352px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 100%;
        width: 100%;
        padding: 8px;
        object-fit: contain;
      }
    }
  }
}

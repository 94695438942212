.drawer__outside {
  z-index: 2236374119;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;

  position: fixed !important;
  top: 0 !important;
  left: 0;
  .drawer__inside {
    background-color: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: fit-content;
    border-radius: 16px 16px 0px 0px;
    animation: drawer-up 0.3s ease-in;
  }
}

@keyframes drawer-up {
  0% {
    transform: translateY(50vh);
  }
  100% {
    transform: translateY(0vh);
  }
}

@media screen and (min-width: 1024px) {
  .drawer__outside {
    .drawer__inside {
      width: 375px;
      top: 50%;
      left: 50%;

      border-radius: 16px;

      transform: translate(-50%, -50%);
      animation: none;
    }
  }
}

.container {
  margin-bottom: 24px;

  .title {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.85);
  }

  .description {
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.45);
  }

  .check__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 16px;
    margin-bottom: 1px;
    border: 1px solid #f0f0f0;

    &:first-of-type {
      border-radius: 8px 8px 0 0;
    }
    &:last-of-type {
      border-radius: 0 0 8px 8px;
    }
    &:first-of-type:nth-last-child(1) {
      border-radius: 8px;
    }
  }
}

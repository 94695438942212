.section {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px 14px 20px;
  gap: 16px;
  border-bottom: 8px solid #f5f5f5;
  & > a {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    & > div:first-child {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      width: 72px;
      height: 56px;
      background: #f5f5f5;
      border-radius: 40px;
      & > img {
        width: 40px;
        height: 40px;
      }
    }
    & > div:last-child {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      & > span:first-child {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.65);
      }
      & > span:last-child {
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        color: #418dff;
      }
    }
  }
}

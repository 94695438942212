@import '../../styles/globals';
.checkout-desktop-bg {
  width: 100%;
  background: #fafafa;
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  // padding: 32px $page_padding;
  flex-direction: column;
  max-width: none !important;
  padding: 0 !important;

  & + footer {
    margin: 0;
  }
}

.checkout-desktop-container {
  width: 100%;
  height: 100%;
  max-width: $desktop_max_width;
  margin: 0 auto;
  padding: 48px 20px 32px;
  .checkout-item-container {
    display: flex;
    gap: 24px;
    .checkout-item__left-inner-container {
      flex: 65%;
      background: #fafafa;
      border-radius: 8px;
      & > section:first-child {
        & > div {
          border: unset;
          background: #ffffff;
          border-radius: 8px;
          margin-bottom: 24px;
          & > div {
            position: relative;
            background: #ffffff;
            margin-bottom: 0;
            justify-content: flex-end;
            & > div:first-of-type {
              display: -webkit-box;
              overflow-x: auto;
              overflow-y: hidden;
              height: 100%;
              & > div:not(:last-of-type) {
                width: 50%;
                height: 112px;
              }
              & > div:last-of-type {
                position: absolute;
                right: 16px;
                top: 16px;
              }
            }
          }
        }
      }
      & > section:nth-child(3) {
        & > article {
          min-height: 64px;
          & > div:first-child {
            img:first-child:nth-last-child(2),
            img:first-child:nth-last-child(2) ~ img {
              &:last-of-type {
                display: none;
              }
            }
          }
        }
        & > main > form {
          border-radius: 8px;
          background-color: #ffffff;
          & > section:first-child {
            padding: 8px 16px;
            & > h2 {
              display: none;
            }
            & > div:first-of-type {
              margin-top: 0;
            }
            & > div:last-of-type {
              margin-left: 0;
              margin-top: 16px;
            }
          }
          & > section:last-child {
            border-radius: 0 0 8px 8px;
            background: #f5f5f5;
            margin-bottom: 24px;
            & > div {
              height: unset;
            }
          }
        }
      }
    }
    .checkout-item__right-inner-container {
      padding: 24px 16px 16px 16px;
      flex: 35%;
      height: fit-content;
      position: sticky;
      top: 0;
      background-color: #ffffff;
      border-radius: 8px;
      h3 {
        margin: 8px 0 24px 0;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);
      }
      .purchase-summary-custom {
        padding: 8px 0 0 0 !important;
        flex-direction: column;
        align-items: flex-start;
        cursor: unset;
        & > div {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          h4 {
            font-weight: 400;
            font-size: 13px;
            line-height: 140%;
            color: rgba(0, 0, 0, 0.85);
          }
          span {
            font-family: 'Noto Sans CJK KR';
            font-weight: 400;
            font-size: 13px;
            line-height: 140%;
            color: rgba(0, 0, 0, 0.85);
          }
        }
        & > div:not(:first-child, :nth-child(3)) {
          padding-top: 16px;
        }
        & > div:nth-child(2) {
          cursor: pointer;
          padding-bottom: 16px;
          & > div {
            display: flex;
            gap: 4px;
            align-items: center;
            & > div {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 40px;
              height: 40px;
              border: 1px solid #f5f5f5;
              img {
                width: 99.9%;
                height: 99.9%;
              }
              span {
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 140%;
                color: #000000;
              }
            }
          }
        }
        & > div:last-child {
          h4,
          span {
            font-weight: 700;
          }
        }
      }
    }
  }
}

.purchase-summary-custom {
  & > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & > div {
    cursor: pointer;
    & > div {
      display: flex;
      gap: 4px;
      align-items: center;
      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border: 1px solid #f5f5f5;
        img {
          width: 99.9%;
          height: 99.9%;
        }
        span {
          font-weight: 400;
          font-size: 13px;
          line-height: 140%;
          color: #000000;
        }
      }
    }
  }
}
.point-deduction-text {
  color: #da3730 !important;
}

.purchase-info-container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #ffffff;
  border-radius: 8px;
  .purchase-info__item {
    display: flex;
    // gap: 16px;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid #f5f5f5;
    &:not(:first-child) {
      padding-top: 16px;
    }
    & > div:first-child {
      width: 64px;
      height: 64px;
      border: 1px solid #f5f5f5;
      cursor: pointer;
      img {
        width: inherit;
        height: inherit;
      }
    }
    & > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 340px;

      p,
      span {
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
        color: rgba(0, 0, 0, 0.85);
      }
      p {
        width: fit-content;
        cursor: pointer;
      }
    }
    & > span {
      min-width: 60px;
      font-weight: 400;
      font-size: 13px;
      line-height: 140%;
      color: rgba(0, 0, 0, 0.85);

      &:last-child {
        min-width: 100px;
      }
    }
  }
}

.checkout-desktop-coupon-apply {
  & > main {
    height: 100%;
    & > section {
      article {
        width: 100%;
      }
    }
  }
}

.checkout-desktop-add-new-card {
  & > main > form {
    padding: 0;
  }
}

.checkout__alert {
  font-size: 14px;
  color: #da3730;
  margin-bottom: 24px;
  line-height: 22px;
  font-weight: 500;
}

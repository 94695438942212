@import '../../styles/elements';

.container {
  width: 100%;
  background-color: #fff;

  .search__container {
    width: 100%;
  }

  .search__container--searching {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px;
    width: 100%;
    border-bottom: 1px solid #f0f0f0;
  }

  .back {
    display: block;
  }

  .back--hidden {
    display: none;
  }

  .search {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;
    padding: 0 8px;
    height: 40px;
    background: rgba(245, 245, 245, 0.44);
    border: 1px solid #f5f5f5;
    border-radius: 41px;

    form {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
    }
  }

  .search--searching {
    border: 1px solid #1f317e;
  }

  .search__img {
    width: 24px;
    height: 24px;
  }

  .search__input {
    padding: 8px 16px;
    padding-right: 0px;
    flex: 1;
    border: none;
    background-color: transparent;
  }

  .search__input:focus {
    outline: none;
  }

  .search__input::placeholder {
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.45);
  }

  .search__clear {
    margin-right: 16px;
    cursor: pointer;
  }

  .search__button {
    background-color: #1f317e;
    border-radius: 37px;
    color: white;
    padding: 3px 0px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    min-width: 50px;

    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    &:active {
      @extend %button-active;
    }
  }

  .search__clear--hidden {
    display: none;
  }
}

.container--searching {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
}

@media screen and (min-width: 1024px) {
  .container {
    .search__container--searching {
      border: none;
    }

    .back {
      display: none;
    }

    .search__keywords {
      position: absolute;
      width: 100%;
      min-width: 560px;
      background-color: #fff;
      border-radius: 8px;
      border: 1px solid #f5f5f5;
    }
  }

  .container--searching {
    position: relative;
  }
}

.article {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 160px;
  height: 274px;

  &.article--LEFT {
    align-items: flex-start;
  }
  &.article--RIGHT {
    align-items: flex-end;
  }
  &.article--CENTER {
    align-items: center;
  }
  &.article--DEFAULT {
    align-items: normal;
  }

  .product-flag-image {
    position: relative;
    z-index: 9;
    display: flex;
    flex-direction: column;
    align-items: center;

    .product-flag {
      position: absolute;
      top: 0;
      display: flex;
      width: 100%;
      height: 24px;

      .flag {
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 8px;
        width: 42px;
        margin-right: 3px;

        background-color: #da3730;

        &.best {
          background-color: #ff9835;
        }

        &.new {
          background-color: #2f4ccb;
        }

        span {
          font-weight: 700;
          font-size: 13px;
          line-height: 140%;
          color: #ffffff;
        }
      }
    }

    .product-image {
      position: relative;
      width: 160px;
      height: 160px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      & > img {
        height: 100%;
      }
    }

    .product-sold-out {
      z-index: 109;
      width: 100%;
      height: 100%;
      position: absolute;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #ffffff;
        position: relative;
        bottom: 20px;
      }
    }
  }

  .product-name {
    max-width: 150px;
    min-height: 44.78px;
    margin: 8px 0;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.65);

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .product-price {
    max-width: 150px;
    width: 100%;
    display: flex;
    justify-content: flex-start;

    .current-price {
      margin-right: 4px;

      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: #da3730;
    }

    .original-price {
      line-height: 22px;
      text-decoration-line: line-through;
      color: rgba(0, 0, 0, 0.45);
    }
  }

  .product-rating {
    max-width: 150px;
    min-height: 18px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 8px;
    img {
      width: 18px;
      height: 18px;
    }
    small {
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: rgba(0, 0, 0, 0.45);
    }
  }

  @media screen and (max-width: 1023px) {
    width: 140px;
    height: 246px;

    .product-flag-image {
      width: 140px;
      height: 140px;
      .product-image {
        position: relative;
        width: 140px;
        height: 140px;
      }
      .product-flag {
        .flag {
          padding: 2px 6px;
          width: 32px;
          height: 18px;
          margin-right: 2px;
          span {
            font-size: 10px;
            line-height: 140%;
          }
        }
      }
    }
    .product-name {
      // margin: 16px 0 13px 0;
      max-width: 140px;
      height: 36px;
      font-size: 12px;
      line-height: 20px;
    }
    .product-price {
      max-width: 140px;
      .current-price {
        font-size: 16px;
        line-height: 24px;
      }
      .original-price {
        font-size: 12px;
        line-height: 22px;
      }
    }
    .product-rating {
      margin-top: 4px;
      img {
        width: 14px;
        height: 14px;
      }
      small {
        font-size: 12px;
      }
    }
  }
}

.register-outer {
  display: flex;
  flex-direction: column;
  padding: 16px !important;
  max-width: 391px !important;
  width: 100% !important;
  min-width: auto !important;
  margin-bottom: auto;
}
.register-container {
  display: flex;
  flex-direction: column;
  padding: 16px !important;
  max-width: 391px !important;
  width: 100% !important;
  min-width: auto !important;
  margin-bottom: 24px;

  .register-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 8px;
  }
  .register-description {
    font-size: 12px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.45);
  }

  .register-invite-discount-image {
    position: relative;
    width: 100%;
    height: 180px;
    margin: 24px 0 8px 0;
    span {
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      margin: 0 auto;
      top: 24px;

      font-weight: 500;
      font-size: 18px;
      line-height: 140%;
      color: #1f317e;
    }
  }
}

@media screen and (min-width: 1024px) {
  .register-container {
    margin-top: 32px;
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 48px;
  }
}

@import '../../styles/variables';
.loading {
  margin-top: 24px;
  width: 100%;
  min-height: 70vh;
}
.empty {
  width: 100%;
  background-color: #fafafa;
  min-height: 70vh;
}
.product__detail__path {
  display: none;
  font-size: 16px;
  margin-top: 32px;
  margin-right: auto;
}

.product__detail__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.product__detail {
  width: 100%;
  border-bottom: 8px solid #f5f5f5;
}

.product__cart {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  padding: 10px 16px 16px 16px;
  width: 100%;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.05);
}

.product__container {
  width: 100%;
  border-bottom: 8px solid #f5f5f5;
}

.divider {
  width: 100vw;
  height: 1px;
  background-color: #f0f0f0;
}

@media screen and (min-width: 1024px) {
  .loading {
    margin-top: 100px;
    min-height: auto;
    margin-bottom: auto;
  }
  .empty {
    margin-top: 24px;
    min-height: auto;
    margin-bottom: auto;
  }
  .product__detail__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 $page_padding;
  }

  .product__detail__path {
    display: block;
  }

  .product__detail {
    display: flex;
    width: 100%;
    padding: 32px 0 100px;
    flex-direction: row;
    justify-content: center;
    border-bottom: none;
    // margin-bottom: 32px;
  }

  .product__cart {
    display: none;
  }

  .product__container {
    width: 100%;
    border-bottom: none;
  }
}

@import '../../styles/mixins';

.border-separator {
  @include border-separator(16px, 100%, 8px);
}

.margin-top-8px {
  margin-top: 8px;
}

.no-margin-top {
  margin-top: 0;

  display: flex;
  justify-content: center;
  width: 100vw;
  max-width: none !important;
  min-width: auto !important;
}

.no-margin-all {
  margin: 0;
}

.height-1px {
  height: 1px;
}

.no-margin-top-and-height-1px {
  margin-top: 0;
  height: 1px;
}

.border-horizon {
  @include border-separator(0px, 100vw, 8px);

  display: flex;
  justify-content: center;
  width: 100vw;
  max-width: none !important;
  min-width: auto !important;
}

.border-horizon-absolute {
  @include border-separator(0px, 100vw, 8px);

  display: flex;
  justify-content: center;
  width: 100vw;
  max-width: none !important;
  min-width: auto !important;
  position: absolute;
  left: 0
}

.border-horizon-and-height-1px {
  @include border-separator(0px, 100vw, 1px);

  display: flex;
  justify-content: center;
  width: 100vw;
  max-width: none !important;
  min-width: auto !important;
}

.cart-number-notification {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 6px;
  width: 32px;
  height: 23px;
  left: 24px;
  top: -8px;
  background: #da3730;
  border-radius: 16px;
  & > span {
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }
}

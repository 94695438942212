@import '../../../styles/elements';

.button-container {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  &:active {
    @extend %button-active;
  }
  &.disabled {
    cursor: not-allowed;
    background: #f0f0f0;
    div,
    span {
      color: #bfbfbf !important;
    }
    &:active {
      position: unset;
    }
  }
}

.nav {
  width: 100vw;
  display: flex;
  flex-direction: column;

  background-color: white;
  position: sticky;
  top: 0;
  z-index: 10001;

  .nav__container {
    width: inherit;
    display: flex;
    flex-direction: column;


    .nav__items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 16px 0 16px;

      & > div:first-child,
      & > div:last-child {
        display: flex;
        gap: 16px;
      }
    }

    .nav__logo {
      a {
        img {
          width: 80px;
          height: 31.26px;
        }
      }
    }

    .nav__search {
      padding: 0 24px 8px 24px;
    }
  }
}

.container {
  position: relative;
  cursor: pointer;
  margin-bottom: 16px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  .container__border--selected {
    position: absolute;
    width: 100%;
    height: 100%;
    border: solid 4px rgba(66, 103, 255, 0.6);
    border-radius: 8px;
    z-index: 1;
  }

  .coupon__infomation {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 24px 24px 24px;
    border-radius: 8px;
    background-color: #ffffff;

    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
    }

    h3 {
      font-size: 14px;
      line-height: 140%;
    }

    .coupon__title {
      color: rgba(0, 0, 0, 0.85);
    }
    .coupon__description {
      color: rgba(0, 0, 0, 0.65);
    }

    &::after,
    &::before {
      z-index: 999;
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      background-color: #fafafa;
      border-radius: 20px;
      bottom: -12px;
    }

    &::before {
      box-shadow: -10px 0px 10px -8px rgba(0, 0, 0, 0.2) inset;
      left: -12px;
    }

    &::after {
      box-shadow: 10px 0px 10px -8px rgba(0, 0, 0, 0.2) inset;
      right: -12px;
    }
  }

  .coupon__information--selected {
    &::before,
    &::after {
      width: 12px;
      box-shadow: none;
      z-index: 999;
    }

    &::before {
      border-radius: 0 20px 20px 0;
      left: 0;
      background: radial-gradient(
        circle at 0%,
        #f5f5f5 0%,
        #f5f5f5 51%,
        rgba(66, 103, 255, 0.6) 0%,
        rgba(66, 103, 255, 0.6) 100%
      );
    }

    &::after {
      border-radius: 20px 0 0 20px;
      right: 0;
      background: radial-gradient(
        circle at 100%,
        #f5f5f5 0%,
        #f5f5f5 51%,
        rgba(66, 103, 255, 0.6) 0%,
        rgba(66, 103, 255, 0.6) 100%
      );
    }
  }

  .container__border {
    width: 100%;
    height: 0px;
    border: 1px dashed rgba(0, 0, 0, 0.2);
  }

  .coupon__metadata {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 8px 24px;
    border-radius: 8px;

    font-size: 12px;
    color: #ffffff;
    line-height: 18px;

    .coupon__tag {
      position: absolute;
      right: 24px;
      padding: 8px 16px;
      background: #f5f5f5;
      border: 1px solid #afafaf;
      border-radius: 34px;
      font-size: 14px;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.45);
    }

    &.disabled {
      background: #f5f5f5;
      color: rgba(0, 0, 0, 0.45);
    }
  }

  // Coupon's color
  .fixed_amount--primary {
    background-color: #1f317e;
  }
  .fixed_amount--secondary {
    background-color: #7d9da7;
  }
  .fixed_amount--third {
    background-color: #7254a1;
  }
  .percetage--primary {
    background-color: #7d9da7;
  }
  .percetage--secondary {
    background-color: #70410a;
  }
  .percetage--third {
    background-color: #7f4452;
  }
  .shipping_line--primary {
    background-color: #ad7575;
  }

  .disabled {
    background: #ffffff;
    color: rgba(0, 0, 0, 0.45);
  }
}

.container--disabled {
  cursor: default;
}

.container--selected {
  border-radius: 12px;
  box-shadow: none;
}

@media screen and (min-width: 768px) {
  .container {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    width: calc(50% - 20px);
  }
}

.toast-container {
  cursor: pointer-events-none;
  position: fixed;
  width: 100%;
  z-index: 2147483649;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
}

.toast-button {
  cursor: pointer-events-auto;
  width: fit-content;
  padding: 8px 36px;
  text-align: center;
  font-size: 14px;
  line-height: 140%;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 27px;
}

@media screen and (min-width: 1024px) {
  .toast-container {
    bottom: 16px;
  }
}

.layout {
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 0;
}

.container {
  padding: 16px;
  background-color: #f5f5f5;
}

.category {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.item__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.65);
}

.item__image__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  background-color: #f5f5f5;
  border-radius: 40px;
}

.item__title {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.01em;

  text-align: center;
}

.item__container--selected {
  background-color: #4267ff;
  color: #fff;
  padding: 8px;
  border-radius: 16px;
}

@media screen and (min-width: 1024px) {
  .category {
    justify-content: flex-start;
    gap: 8px;
  }

  .container {
    background-color: #fff;
    padding: 0;
  }

  .item__container {
    flex-direction: row;
    background-color: #fafafa;
    border-radius: 32px;
    padding: 0 16px;
    height: 40px;
    gap: 8px;
  }

  .item__container--selected {
    background-color: #4267ff;
    color: #fff;
  }

  .item__image__container {
    background-color: transparent;
    padding: 0;
    width: 32px;
    height: 32px;
    img {
      height: 100%;
    }
  }
}

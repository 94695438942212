.cart-button-container {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  .cart-button-container--left {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border: 1px solid #d9d9d9;
    border-radius: 40px;
    &.cart-button-container--left-fill {
      background: #1f317e;
    }
    & > img {
      width: 24px;
      height: 24px;
    }
  }
  .cart-button-container--right {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    flex: 1;
    height: 48px;
    background: #1f317e;
    border-radius: 40px;
    & > .cart-button-container--right-inner {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      & > div {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        background: #ffffff;
        border-radius: 34.2857px;
        & > .cart-button__minus {
          width: 24px;
          height: 24px;
        }
        & > .cart-button__trash {
          width: 20px;
          height: 20px;
        }
      }
      & > div:first-of-type {
        left: -4px;
      }
      & > div:last-of-type {
        right: -4px;
      }
      & > span {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
      }
    }
  }
}

.cart-button-bell {
  margin-right: 8px;
}

.cart-button-cart {
  display: flex;
  gap: 8px;
}

@media screen and (min-width: 1024px) {
  .cart-button-container {
    .cart-button-container--left {
      display: none;
    }

    .cart-button-container--right {
      // width: auto;
      max-width: none;
    }
  }
}

.section {
  padding: 16px;
  margin: auto;
  height: fit-content;
  display: flex;
  flex-direction: column;

  .review-top-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > div:first-child {
      display: flex;
      align-items: center;
      span {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);
      }

      small {
        margin-left: 8px;

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: rgba(0, 0, 0, 0.45);
      }

      .review-star-rating--average {
        margin-left: 8px;
        display: flex;
        align-items: center;
        & > img {
          width: 16px;
          height: 16px;
        }
        & > span {
          margin-left: 4px;

          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          color: rgba(0, 0, 0, 0.65);
        }
      }
    }
    & > div:last-child {
      .review-write__text {
        cursor: pointer;
        margin: 0 2px 0 4px;

        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 19px;
        text-align: center;
        text-transform: uppercase;
        color: #595959;
      }
      .review-top-container--right-star-grey {
        display: flex;
        align-items: center;
        gap: 8px;
        & > img {
          width: 16px;
          height: 16px;
        }
        & > span {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          color: rgba(0, 0, 0, 0.65);
        }
      }
    }
  }
  .review-contents-container {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    .review-first-button--text {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;
      text-align: center;
      color: rgba(0, 0, 0, 0.65);
    }

    .first-write-review-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      padding-top: 8px;
      padding-bottom: 16px;

      .review-write-button {
        padding: 8px;
        width: 225px;
        height: 40px;
        background: #f5f5f5;
        border: 1px solid #bfbfbf;
        border-radius: 4px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .section {
    padding: 32px 0;
  }
}

.login-container {
  display: flex;
  flex-direction: column;
  padding: 16px !important;
  background-color: #fff;
  width: 100% !important;
  min-height: 90vh;
  max-width: 391px !important;
  min-width: auto !important;
  margin-bottom: 48px;

  .login-title {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 16px;
    line-height: 32px;
  }
}

.text--bold {
  font-weight: 700;
}

.radio-container--top {
  border-radius: 4px 4px 0px 0px;
}

.radio-container--bottom {
  border-radius: 0px 0px 4px 4px;
}

.radio-container--top,
.radio-container--middle,
.radio-container--bottom {
  display: flex;
  flex-direction: column;
  border: 1px solid #efefef;
  padding: 16px;
}

.radio-container--middle {
  gap: 16px;
}

.radio-container--bottom {
  padding: 16px;
}

.radio-container--checked {
  background-color: #fafafa;
}

.login-policy {
  margin-top: 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.45);

  a {
    color: #1f317e;
  }
}

.login-help {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  margin-top: 16px;
  color: #1f317e;

  img {
    margin-right: 5px;
  }
}

@media screen and (min-width: 1024px) {
  .login-outer {
    display: flex;
    justify-content: center;
    margin-bottom: auto;
  }
  .login-container {
    margin-top: 32px;
    align-items: center;
    border-radius: 8px;
    min-height: auto;

    .login-title {
      margin-bottom: 24px;
    }
    & > .login-title + .login-inner-container {
      padding: 0;
    }
  }
}

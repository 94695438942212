.information__bg {
  background-color: #f5f5f5;
}

.layout {
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.container {
  padding: 16px;
  background-color: #f5f5f5;
  margin-bottom: 32px;

  img {
    width: 100%;
    border: 1px solid #f0f0f0;
  }
}

.tab__layout {
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 0;
}

.tab__container {
  padding: 16px;
}

.tab__category {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

@media screen and (min-width: 1024px) {
  .information__bg {
    background-color: unset;
    min-height: unset;
  }

  .container {
    border-radius: 8px;
    background-color: #fafafa;
  }

  .tab__category {
    justify-content: flex-start;
    gap: 20px;
  }

  .tab__container {
    background-color: #fff;
    padding: 0;
  }
}

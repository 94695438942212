.footer {
  width: 100vw;
  background: #f5f5f5;
  padding: 40px 0;

  .mobile-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > div {
      padding-left: 10.5vw;
      h5,
      p,
      a {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.65);
      }

      h3 {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);
      }

      p {
        margin-top: 24px;
        width: 65%;
      }

      h5:nth-of-type(2) {
        margin-top: 40px;
      }

      h3 {
        margin-top: 8px;
      }

      & > div {
        margin-top: 40px;
        display: flex;
        gap: 64px;

        & > div {
          display: flex;
          flex-direction: column;
          h4 {
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.85);
          }
          & > div {
            margin: 16px 0;
            width: 40px;
            height: 2px;
            background: rgba(0, 0, 0, 0.65);
          }
          a {
            margin-bottom: 8px;
          }
        }
      }

      h5:nth-of-type(3) {
        margin-top: 32px;
      }

      h5:last-of-type {
        margin-top: 16px;
      }
    }
  }

  .desktop-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding: 0 40px;
    width: fit-content;
    justify-content: center;
    margin: 0 auto;

    & > div:first-child {
      display: flex;
      gap: 6.5vw;
      & > div:first-child {
        flex: 28%;
        h3 {
          font-weight: 700;
          font-size: 20px;
          line-height: 32px;
          color: rgba(0, 0, 0, 0.65);
        }
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          color: rgba(0, 0, 0, 0.65);
          margin-top: 24px;
          max-width: 240px;
        }
      }

      & > div:nth-child(2) {
        flex: 18%;
      }

      & > div:nth-child(3) {
        flex: 16%;
      }

      & > div:nth-child(2),
      & > div:nth-child(3) {
        display: flex;
        flex-direction: column;
        h4 {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.85);
        }

        & > div {
          margin: 16px 0;
          width: 40px;
          height: 2px;
          background: rgba(0, 0, 0, 0.65);
        }

        a {
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: rgba(0, 0, 0, 0.65);
          margin-bottom: 8px;
        }
      }

      & > div:last-child {
        flex: 22%;
        display: flex;
        flex-direction: column;

        h5 {
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: rgba(0, 0, 0, 0.65);
        }

        h4 {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.85);
          margin-top: 8px;
        }
      }
    }

    & > div:last-child {
      width: 100%;
      margin-top: 32px;

      span {
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
        color: rgba(0, 0, 0, 0.65);
        margin-right: 16px;
      }
    }
  }

  @media screen and (max-width: 1023px) {
    margin-top: 0;
  }
}

.footer-remove-top-padding {
  padding-top: 0;
}

.mobile-footer-bottom-pading {
  padding-bottom: 60px;
}
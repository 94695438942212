.form-input-container {
  position: relative;
  width: 100%;

  .form-input-inner {
    position: relative;
    width: 100%;
    margin-bottom: 8px;
  }

  .form-input-input {
    position: relative;
    width: inherit;
    padding: 16px;
    border: 1px solid #f0f0f0;
    border-radius: 8px;

    font-size: 14px;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.85);

    &:focus {
      outline: none;
      border: 1px solid #1f317e;
    }

    &:placeholder-shown + span {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      padding: 0 16px;
      font-size: 14px;
      line-height: 140%;
      color: rgba(0, 0, 0, 0.25);
      white-space: pre-line;
    }

    &:not(:placeholder-shown) + span {
      display: none;
    }
  }

  .form-input--password-image {
    cursor: pointer;
    z-index: 1;
    position: absolute;
    right: 12px;
    top: 16px;
    width: 24px;
    height: 24px;
  }
}

.form-input-container {
  .form-input-input--danger {
    border: 1px solid #ff4d4f;
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input__error {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 11px;
  color: #da3730;

  .input__error__image {
    margin-right: 4px;
    width: 16px;
    height: 16px;

    img {
      width: 16px;
      height: 16px;
    }
  }
}

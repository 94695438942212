.aside {
  width: 240px;
  margin-right: 40px;
  & > div:last-child {
    margin-bottom: 48px;
  }
}

.aside__profile {
  padding: 0 0 16px 0;

  img {
    width: 56px !important;
    height: 56px !important;
    border-radius: 28px;
    object-fit: cover;
  }
}

.aside__container {
  width: 240px;
  padding: 16px 0;
  border-bottom: 1px solid #f5f5f5;
}

@import '../../../styles/globals.scss';
.category_nav {
  width: inherit;
  // height: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
}

.nav_container {
  width: inherit;
  height: 72px;
  // padding: 0 $page_padding;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
}
.nav {
  @include desktop_width;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  z-index: 1;

  .nav__logo {
    // margin-right: $page_padding;
    a {
      img {
        width: 120px;
        height: 46.89px;
      }
    }
  }

  .nav__items {
    // margin-left: 9.2vw;
    display: flex;
    gap: 40px;
    margin-right: 16px;
  }

  .nav__search {
    width: 100%;
  }
}

.nav--only-title {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 12px 0;
  background-color: #fff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
}

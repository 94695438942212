.form {
  display: flex;
  flex-direction: column;
  padding: 16px;

  .form__container {
    margin-bottom: 8px;
  }
}

.input__container--gap {
  display: flex;
  gap: 8px;
}

@media screen and (min-width: 1024px) {
  .form__button__container {
    flex-direction: row;
    width: fit-content;
    margin-left: auto;
  }

  .form__container {
    padding: 16px;
    border: 16px solid #fafafa;
    border-radius: 8px;
    margin-bottom: 32px;
  }

  .form {
    padding: 0px;
  }
}

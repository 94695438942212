.main {
  min-height: 100vh;
  height: 100%;
  background: #fafafa;
}

@media screen and (min-width: 1024px) {
  .main {
    min-height: unset;
    height: unset;
    border-radius: 8px;
    background-color: #fafafa;
  }
}

@media screen and (max-width: 1023px) {
  .main {
    padding-bottom: 64px;
  }
}

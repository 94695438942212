.container {
  width: 100%;
  padding: 0 16px;
}

.item {
  padding: 12px 8px;
  border-bottom: 1px solid #f0f0f0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;

  span {
    font-weight: 700;
  }
}

.container {
  width: 100%;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  user-select: none;
  background-color: #ffffff;
}

.container--center {
  justify-content: space-around;
}

.tab__item {
  padding: 16px 0;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  text-align: center;
}

.tab__item--desktop {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  height: 40px;
  padding: 0 16px;

  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;

  background-color: #f5f5f5;
  border-radius: 32px;

  margin-bottom: 16px;

  img {
    height: 32px;
  }
}

.tab__item--active {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.85);
  border-bottom: 2px solid #1f317e;
}

.tab__item--active--desktop {
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  background-color: #4267ff;
}

@media screen and (min-width: 1024px) {
  .container {
    padding: 0;
  }
}

.help__bg {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
}

.layout {
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  border: 1px solid #f0f0f0;
}

.container {
  padding: 16px;
  background-color: #f5f5f5;

  .title {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
  }

  .faq {
    > :first-child {
      border-radius: 8px 8px 0 0;
    }

    > :last-child {
      border-radius: 0 0 8px 8px;
    }

    color: rgba(0, 0, 0, 0.85);

    .faq__title {
      display: flex;
      align-items: center;
      height: 60px;
      font-size: 14px;
      line-height: 22px;
      color: inherit;
    }

    .faq__description {
      font-size: 12px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.65);
    }
  }
}

@media screen and (min-width: 1024px) {
  .help__bg {
    background-color: unset;
    min-height: unset;
    margin-bottom: 48px;
  }

  .container {
    border-radius: 8px;
    background-color: #fafafa;
  }
}

.product__container {
  display: flex;
  flex-direction: row;

  background-color: #ffffff;
  margin-bottom: 8px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #f0f0f0;

  .product__thumnail {
    width: 80px;
    height: 80px;
    border: 1px solid #f5f5f5;
    margin-right: 16px;

    img {
      height: 100%;
      object-fit: contain;
    }
  }

  .product__detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 215px;

    .product__title {
      font-size: 14px;
      line-height: 140%;
      color: rgba(0, 0, 0, 0.85);
    }

    .product__price-quantity {
      display: flex;
      gap: 16px;
      @media screen and (max-width: 1023px) {
        display: block;
      }
      .product__price {
        font-size: 12px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.65);
      }

      .product__quantity {
        font-size: 12px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
      }
    }
  }
}

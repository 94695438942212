.category {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 90vh;
  flex: 1;
  .category__container {
    display: flex;
    flex-direction: column;
    max-width: 1056px;
    width: 100%;
    flex: 1;
  }
  .category__container__loading {
    width: 100%;
  }
  .category__container__products {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
  }
}

.tab__items {
  width: 100vw;

  @media screen and (max-width: 1023px) {
    border-bottom: 1px solid #f0f0f0;
  }

  .tab__item {
    font-size: 13px;
    width: 100;
    color: rgba(0, 0, 0, 0.45);
    padding: 16px 0;
    text-align: center;
  }

  .tab__item--active {
    font-weight: 700;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 2px solid #1f317e;
  }
}

@media screen and (min-width: 1024px) {
  .category {
    min-height: auto;
    width: 100%;
    .category__container {
      flex-direction: row;
      width: 100%;
      max-width: none;
      flex: 1;
    }
    .category__container__loading {
      padding: 30px;
    }
    .category__container__products {
      background-color: white;
    }
  }
}

.result__header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 16px;

  .result__header__count {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);

    span {
      font-weight: 700;
      color: #418dff;
    }
  }
}

.select__container {
  position: absolute;
  top: 32px;
  right: 0;
  width: 300px;
  height: 32px;
}

@import '../../../styles/globals.scss';
.div {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  &.no-align {
    align-items: unset;
  }

  & > :first-child {
    background-color: white;
    z-index: 10001;
    position: sticky;
    top: 0;
  }
}

@media screen and (min-width: 1024px) {
  .login {
    background-color: #fafafa;
    margin-bottom: auto;
  }
  .div {
    // & > :nth-child(2) {
    //   // top: 68px;
    //   // & > :last-child {
    //   //   @include desktop_width;
    //   // }
    // }
    padding: 0;
    & > :not(nav, footer) {
      @include desktop_width;
    }
  }
}

// @media screen and (max-width: 1023px) {
//   .div {
//     padding-bottom: 72px;
//   }
//   .checkout-mobile-layout {
//     padding-bottom: 72px;
//   }
// }

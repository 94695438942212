.password-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 16px !important;
  width: 100% !important;
  max-width: 391px !important;
  min-width: auto !important;
  margin-bottom: 48px;
  min-height: 90vh;

  .password-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }

  .password-description {
    margin-top: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
  }

  .password-subdescription {
    padding: 0 !important;
    margin-top: 8px;
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.45);

    .customer-service {
      cursor: pointer;
      color: #1f317e;
    }
  }
}

@media screen and (min-width: 1024px) {
  .password-container {
    margin-top: 32px;
    padding: 24px;
    width: 391px;
    border-radius: 8px;
    min-height: auto;
  }
}

.div {
  width: 100%;

  :global(.slick-list) {
    margin-right: 1.8rem;
  }
  .article {
    width: 258px;
    height: 126px;
    display: flex;
    align-items: center;
    gap: 2px;
    padding: 8px;
    background: #ffffff;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    margin-bottom: 16px;
    & > div:first-child {
      display: flex;
      flex-direction: column;
      gap: 2px;
      & > div:first-of-type {
        display: flex;
        align-items: center;
        gap: 8px;
        & > img {
          width: 24px;
          height: 24px;
        }
        & > span {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.45);
        }
      }
      & > p {
        width: 130px;
        height: 60px;

        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.65);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* 라인수 */
        -webkit-box-orient: vertical;
        word-wrap: break-word;
      }
      & > div:last-of-type {
        display: flex;
        align-items: center;
        & > img {
          width: 14px;
          height: 14px;
        }
        & > span {
          margin: 0 8px 0 4px;

          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: rgba(0, 0, 0, 0.45);
        }
        & > small {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.45);
        }
      }
    }
    & > div:last-child {
      & > img {
        width: 110px;
        height: 110px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .div {
    :global(.slick-list) {
      margin-right: 8px;
    }
  }
}

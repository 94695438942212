.container {
  .user__container {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:first-child {
      border-bottom: 8px solid #f5f5f5;
    }

    .user__information {
      width: 100%;
      border-bottom: 8px solid #f5f5f5;
    }
  }

  .checkbox--margin {
    width: fit-content;
    margin: 8px 0 2px 0;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    .user__container {
      border: 16px solid #fafafa;
      border-radius: 8px;
      margin-bottom: 24px;

      .user__information {
        border-bottom: 16px solid #fafafa;
      }
    }

    .checkbox--margin {
      margin-bottom: 24px;
    }
  }
}

.not-active {
  cursor: default;
}

@import '../../../styles/globals';
.div {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .container--desktop {
    @include desktop_width;
    display: flex;
    flex-direction: row;
    justify-content: center;
    top: 72px;
    // margin: 32px 32px 0px;
    padding-top: 32px;
  }

  .container__content {
    flex: 1;
    margin-right: 8px;
  }

  .title {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.85);
  }

  & > nav {
    background-color: white;
    z-index: 10001;
    position: sticky;
    top: 0;
  }

  & > :nth-child(2) {
    flex: 1;
    min-height: 90vh;
    // align-items: flex-start;
  }
}

@media screen and (min-width: 1024px) {
  .div {
    height: 100%;
    align-items: center;
    // & > :not(nav, footer) {
    //   top: 72px;
    //   margin: 32px 32px 0;
    // }
    // footer {
    //   margin-top: 40px;
    // }

    & > :nth-child(2) {
      min-height: auto;
    }
  }
}

// @media screen and (max-width: 1023px) {
//   .div {
//     // padding-bottom: 108px;
//     height: 100%;
//   }
//   .account-profile-edit-mobile-layout {
//     padding-bottom: 164px;
//   }
//   .delivery-info-add-mobile-layout {
//     padding-bottom: 164px;
//   }
//   .no-padding-bottom {
//     padding-bottom: 0;
//   }
// }

.checkout-item-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #f5f5f5;
  padding: 16px 16px 64px 16px;
}

.checkout__alert {
  font-size: 14px;
  color: #da3730;
  margin-bottom: 24px;
  line-height: 22px;
  white-space: pre-line;
  font-weight: 500;
}

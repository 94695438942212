.p__hide {
  display: none;
}
.hide {
  display: none;
}
.section {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 1280px;
  padding: 32px 24px;
  margin-bottom: 48px;

  .header {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0.85);

    align-self: flex-start;
  }

  .container {
    width: 100vw;
    display: flex;
    gap: 8px;
    flex-direction: column;
    margin-bottom: 40px;

    & > div {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 8px;

      & > span:first-child {
        margin-right: 16px;
        width: 128px;
        font-size: 14px;
        letter-spacing: -0.03em;
        color: rgba(0, 0, 0, 0.45);
      }
      & > span:last-child {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
  & > p {
    font-size: 13px;
    line-height: 160%;
    letter-spacing: -0.02em;
    color: rgba(0, 0, 0, 0.65);
  }
  & > img {
    margin-top: 16px;
    width: 100%;
  }
}

.description {
  width: 100%;
  line-height: 160%;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.65);

  img {
    margin: 16px 0;
    object-fit: cover;
    width: 100%;
  }
}

.shipping {
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .shipping__header {
    background-color: #586ab6;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 0 32px;
    gap: 14px;

    img {
      width: 80px;
      height: 80px;
    }

    h1 {
      font-weight: 900;
      font-size: 20px;
      line-height: 40px;
      color: #ffffff;
    }
  }
  .shipping__item {
    background-color: #f2f3f7;
    width: 100%;
    display: flex;
    flex-direction: column;

    padding: 13px 16px;
    gap: 2px;
    border-left: 12px solid #586ab6;
    h3 {
      color: #1f317e;
      font-weight: 900;
      font-size: 16px;
      line-height: 32px;
    }
    h6 {
      font-weight: 400;
      font-size: 14px;
      line-height: 19.6px;
      color: rgba(0, 0, 0, 0.65);
    }
  }
  .shipping__description {
    width: 100%;
    padding: 24px;
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    gap: 24px;
    h6 {
      font-weight: 400;
      font-size: 13px;
      line-height: 18.2px;

      color: rgba(0, 0, 0, 0.65);
    }
    ul {
      padding-left: 10px;
    }
    li {
      text-indent: -8px;
      list-style-position: inside;
      font-weight: 400;
      font-size: 13px;
      line-height: 20.8px;
      color: rgba(0, 0, 0, 0.65);
    }
    li:before {
      display: inline-block;
      position: relative;
      margin-right: 6px;
      left: 0;
      top: -3px;
      content: '';
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: #111;
    }
  }
}

@media screen and (min-width: 1024px) {
  .section {
    padding: 32px 0;
    margin: 0;
    .header {
      font-size: 20px;
    }
  }

  .description {
    font-size: 16px;
  }

  .shipping {
    margin-top: 40px;
    .shipping__header {
      padding-bottom: 40px;
      img {
        width: 100px;
        height: 100px;
      }

      h1 {
        font-size: 32px;
      }
    }
    .shipping__item {
      padding: 10px 40px;
      gap: 8px;
      h3 {
        font-size: 24px;
      }
      h6 {
        line-height: 140%;
      }
    }
    .shipping__description {
      h6 {
        font-size: 16px;
        line-height: 140%;
      }
      ul {
        padding-left: 16px;
      }
      li {
        text-indent: -12px;
        font-size: 16px;
        line-height: 160%;
      }
      li:before {
        margin-right: 8px;
        top: -4px;
        width: 5px;
        height: 5px;
      }
    }
  }
}

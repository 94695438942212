.div {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  & > span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.65);
  }
}

.container {
  background-color: #fff;
  padding: 16px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #f0f0f0;

  .title {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.85);
  }

  .subtitle {
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 24px;
  }

  .input-container {
    width: 100%;
    margin-bottom: 24px;

    .input {
      appearance: none;
      width: 100%;
      height: 8px;
      background-color: #f5f5f5;
      background-image: linear-gradient(#1f317e, #1f317e);
      background-repeat: no-repeat;
      border-radius: 8px;
      margin-bottom: 10px;
    }

    .input::-webkit-slider-thumb {
      appearance: none;
      width: 20px;
      height: 20px;
      background-color: #fff;
      border: 4px solid #1f317e;
      border-radius: 10px;
      cursor: pointer;
    }

    .point-range {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      padding: 2px;

      .point-number {
        font-size: 11px;
        color: rgba(0, 0, 0, 0.45);
      }
    }
  }

  .input-description {
    width: fit-content;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #1f317e;
    padding: 4px 16px;
    background-color: #e8ecff;
    border-radius: 24px;
    margin-bottom: 16px;

    transition: transform 0.3s ease;
  }
  & > form > div:last-child {
    width: 100%;
    display: flex;
    justify-content: center;

    & button {
      width: auto;
    }
  }
}

.checkout-guide-info-custom {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20.72px;
  // padding: 0 10px;
  color: rgba(0, 0, 0, 0.65);
  & > b {
    color: #da3730;
    font-weight: 700;
    line-height: 19.6px;
    margin: 0 4px;
  }
  display: flex;
  align-items: center;
  img {
    margin-right: 8px;
  }
}

.shipping-information-container {
  position: relative;
  width: inherit;
  display: flex;
  flex-direction: column;
  gap: 8px;
  & > h3 {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.85);
  }
  & > div:nth-child(2),
  & > div:nth-child(3) {
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.85);
  }
  & > div:nth-child(4) {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.45);
  }
  & > button {
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 6px 12px;
    background-color: #ffffff;
    border: 1px solid #acb6dd;
    border-radius: 37px;
    img {
      width: 16px;
      height: 16px;
    }
    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: rgba(0, 0, 0, 0.65);
    }
  }
}

.carrier-information-custom {
  width: 343px;
  height: 63px;
}

.coupon-element {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  & > span {
    margin-top: 0 !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.85);
  }
  & > div {
    display: flex;
    gap: 8px;
    & > .coupon-form {
      position: relative;
      & > img {
        position: absolute;
        top: 20px;
        right: 16px;
      }
    }
    & > div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 8px;
      flex: 1;
    }
    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-decoration-line: underline;
      color: #418dff;
    }
    input {
      cursor: pointer;
      background-color: white !important;
      margin-top: 0 !important;
    }
    button {
      cursor: pointer;
      margin-top: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      background: #f4f6ff;
      border: 1px solid #1f317e;
      border-radius: 40px;
      width: 82px;
      height: 40px;

      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #1f317e;
    }
  }
}

.purchase-summary-custom {
  flex-direction: column;
  align-items: flex-start;
  cursor: unset;
  & > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4 {
      font-weight: 400;
      font-size: 13px;
      line-height: 140%;
      color: rgba(0, 0, 0, 0.85);
    }
    span {
      font-family: 'Noto Sans CJK KR';
      font-weight: 400;
      font-size: 13px;
      line-height: 140%;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  & > div:not(:first-child, :nth-child(3)) {
    padding-top: 16px;
  }
  & > div:nth-child(2) {
    cursor: pointer;
    padding-bottom: 16px;
    & > div {
      display: flex;
      gap: 4px;
      align-items: center;
      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border: 1px solid #f5f5f5;
        img {
          width: 99.9%;
          height: 99.9%;
        }
        span {
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 140%;
          color: #000000;
        }
      }
    }
  }
  & > div:last-child {
    h4,
    span {
      font-weight: 700;
    }
  }
}

.deleted-coupon-text {
  color: rgba(0, 0, 0, 0.45) !important;
}

.deleted-coupon-input {
  input {
    color: rgba(0, 0, 0, 0.25) !important;
  }
}

.point-deduction-text {
  color: #da3730 !important;
}

.payment-method-exist {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

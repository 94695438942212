.div {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background: #fafafa;
  padding: 16px;
  padding-bottom: 64px;
}

@media screen and (min-width: 1024px) {
  .div {
    min-height: unset;
    height: unset;
    border-radius: 8px;
    padding: 32px 54px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    gap: 40px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background-color: #f5f5f5;
  padding: 16px;
}

.suggestions {
  display: flex;
  flex-direction: column;
  background-color: inherit;
}

.suggestion__message {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 16px;
  margin-bottom: 16px;
  gap: 16px;

  line-height: 24px;
  text-align: center;
  font-weight: 700;

  background-color: #f4f6ff;
  border: 1px solid #acb6dd;
  border-radius: 8px;

  line-break: auto;
}

.button {
  position: fixed;
  width: calc(100% - 16px);
  margin-left: 8px;
  bottom: 16px;
}

@media screen and (min-width: 1024px) {
  .container {
    background-color: #fafafa;
    border-radius: 8px;
    height: unset;
    margin-bottom: 32px;
  }

  .button {
    position: static;
    width: fit-content;
    margin-left: auto;
  }
}

.wishlist__button__container {
  z-index: 10000;
  position: fixed;
  width: 100%;
  bottom: 0px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px 16px 16px;
  gap: 8px;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.05);

  .wishlist__button__img {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    width: 48px;
    height: 48px;

    background-color: #ffffff;
    border: 1px solid #5b4848;
    border-radius: 50%;
  }

  .wishlist__button {
    width: 100%;
    height: 48px;
    padding: 8px;

    background: #1f317e;
    border-radius: 40px;

    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
  }
}
@media screen and (min-width: 1024px) {
  .wishlist__button__container {
    padding: 8px 16px 16px 16px;
  }
  .wishlist__button {
    width: 100%;
    height: 48px;
    padding: 8px;
  }
}

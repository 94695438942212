.fixed-button-container {
  // z-index: 10000;
  // position: fixed;
  // bottom: 16px;
  // left: 0;
  // right: 0;
  // width: 100%;
  // margin: 0 auto;
  // padding: 0 16px;
  z-index: 10000;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px 16px 16px 16px;
  width: 100%;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.05);
}

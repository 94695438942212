@import '../../styles/globals';
.main {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > .category-menu--desktop-1920 {
    margin-bottom: 24px;
  }
}
.loading {
  flex: 1;
}

@media screen and (min-width: 1024px) {
  .main {
    @include desktop_width;
    margin-top: 24px;
  }
}

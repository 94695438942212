.policy__bg {
  background-color: #f5f5f5;
}

.layout {
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.container {
  padding: 16px;
  margin-bottom: 32px;

  .inner__container {
    padding: 16px;
    border-radius: 8px;
    background-color: #fafafa;
  }

  .shopify-policy__title {
    h1 {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 16px;
    }
  }

  .shopify-policy__body {
    padding: 16px;
    background-color: #fff;
    font-size: 14px;
    border-radius: 8px;
    line-height: 22.4px;
    border: 1px solid #f0f0f0;

    .rte {
      margin-bottom: 15px;
      padding: 0;

      li {
        margin-bottom: 0.4em;
      }

      strong {
        font-weight: bolder;
      }
    }
  }

  ol {
    & > li {
      line-height: 25px;
      & > ul > li {
        line-height: 20.72px;
        text-indent: -8px;
        list-style-position: inside;

        &:before {
          display: inline-block;
          position: relative;
          margin-right: 6px;
          left: 0;
          top: -3px;
          content: '';
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background-color: #111;
        }
      }
    }
  }

  ol,
  ul {
    margin: 0 0 15px 20px;
    padding: 0;
  }

  u {
    text-decoration: underline;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border-color: gray;
  }
  th,
  td {
    text-align: left;
    padding: 15px;
    border: 1px solid #e5e5e5;
  }
  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }
}

@media screen and (min-width: 1024px) {
  .policy__bg {
    background-color: unset;
    min-height: unset;
  }

  .layout {
    background-color: #fff;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
  }

  .container {
    border-radius: 8px;

    .title__container {
      padding: 16px 0;

      .title {
        margin-bottom: 10px;
      }
    }
  }
}
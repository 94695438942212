.div {
  width: inherit;
  height: 64px;
  padding: 20px 16px 20px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
  .navbar {
    // width: 33.3%;
    display: flex;
    align-items: center;
    gap: 20px;

    &:nth-of-type(2) {
      // width: 180%;
      justify-content: center;
      text-align: center;
    }

    &:last-of-type {
      min-width: 24px;
      min-height: 24px;
      justify-content: flex-end;
    }
  }
}

.container {
  padding: 16px;

  .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 16px;
  }
}

.form__button {
  margin: 0 16px 16px 16px;
}

@media screen and (min-width: 1024px) {
  .container {
    background-color: #fafafa;
    border-radius: 8px;
    margin-bottom: 32px;

    .title {
      display: none;
    }
  }

  .form__button {
    width: 210px;
    margin: 0 0 0 auto;
  }
}

.form {
  position: relative;
  .loading-container {
    z-index: 100;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

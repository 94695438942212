.apply-coupon-main {
  width: 100%;
  height: calc(100vh - 64px);
  padding: 16px 14px 16px 16px;
}

.coupon-code-input {
  margin-top: 16px;
  & > div {
    padding-left: 32px;
  }
}

.separator {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
  height: 20px;
  margin: 16px 0 24px 0;
  div {
    width: 46.5%;
    height: 1px;
    background-color: #d9d9d9;
  }
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: rgba(0, 0, 0, 0.65);
  }
}

.coupon-select-container {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 24px;
  width: 100%;
  height: 55vh;
  overflow-y: auto;
  background: #fafafa;
  border-radius: 8px;
}

.dim {
  cursor: not-allowed;
  opacity: 0.2;
}

.purchase-item {
  width: 100%;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  & > p {
    font-style: normal;
    width: fit-content;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.85);
  }
  & > div {
    display: flex;
    align-items: center;
    gap: 16px;
    & > img {
      width: 64px;
      height: 64px;
    }
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .product-price-container {
        display: flex;
        flex-direction: column;
        .current-price {
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: #da3730;
        }
        & > small {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: rgba(0, 0, 0, 0.45);
        }
      }
    }
  }
}

.payment-method-main {
  width: 100%;
}

.payment-method-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.85);
}

.payment-method-section {
  padding: 24px 16px;
  .payment-method__simple-payment,
  .payment-method__credit-card {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border: 1px solid #eeeff2;
  }
  .payment-method__simple-payment {
    margin-top: 16px;
    background: #fafafa;
    border-radius: 8px 8px 0px 0px;
    .simple-payment__inner-container {
      margin-top: 16px;
      margin-left: 32px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: flex-start;
      padding: 16px 16px 0 16px;
      background: #ffffff;
      border: 1px solid #f0f0f0;
      border-radius: 8px;
      .simple-payment__item {
        cursor: pointer;
        position: relative;
        width: 100%;
        display: flex;
        gap: 8px;
        padding-bottom: 16px;
        & > img:first-child {
          height: 24px;
        }
        &:not(:last-child) {
          border-bottom: 1px solid #d9d9d9;
        }
        &:last-child {
          margin-left: -12px;
        }
      }
    }
  }
  .payment-method__credit-card {
    border-radius: 0px 0px 8px 8px;
    .credit-card__inner-container {
      margin-top: 24px;
      margin-left: 32px;
      .add-new-card-button {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        padding: 4px 12px 4px 4px;
        background: #f4f6ff;
        span {
          color: #1f317e;
        }
      }

      .add-new-card-button--big {
        width: 100%;
        height: 48px;
        border: 1px solid #1f317e;
        border-radius: 43px;
        @media screen and (min-width: 1024px) {
          width: 280px;
        }
        span {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
        }
      }

      .add-new-card-button--small-container {
        margin-top: 24px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }

      .add-new-card-button--small {
        width: fit-content;
        height: 32px;
        border: 1px solid #acb6dd;
        border-radius: 16px;
        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
}

.billing-address-container {
  margin-top: 18px;
  background: #fafafa;
  padding: 16px;
  & > p {
    margin-top: 4px;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.45);
  }
  .billing-address__inner-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 322px;
    .billing-address__inner-item {
      padding: 16px 8px;
      width: 100%;
      background: #ffffff;
      border-radius: 8px;
    }
  }
}

.payment-method-checkbox-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-left: 16px;
  .payment-method-desktop-save {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 40px;
    width: 118px;
    height: 40px;
    background: #1f317e;
    border-radius: 40px;

    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
    }
  }
}

.add-new-card-main {
  width: 100%;
  form {
    padding: 16px;
  }
}

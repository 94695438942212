.container {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  border-top: 1px solid #f5f5f5;

  .description {
    padding-left: 32px;
  }
}

.header {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 4px;
}

.header__container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
}

.header__title {
  font-size: 14px;
  line-height: 20px;
  max-height: 40px;
  min-height: 32px;
  overflow-y: hidden;
  color: rgba(0, 0, 0, 0.85);
}

.description {
  display: flex;
  flex-direction: row;
}

.thumbnail {
  position: relative;

  width: 66px;
  height: 66px;
  min-width: 66px;
  border: 1px solid #f5f5f5;
  margin-right: 16px;

  img {
    width: 100%;
    height: 100%;
  }

  .thumbnail--soldout {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;

    .cover {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #000;
      text-align: center;
      opacity: 0.5;
    }

    .text {
      position: relative;
      top: 30px;
      font-weight: 700;
      color: #fff;
    }
  }
}

.price {
  display: flex;
  flex-direction: column;
  margin-top: 9px;
  gap: 8px;
  margin-bottom: 3px;

  .price--selling {
    font-weight: 700;
    color: #da3730;
  }
  .price--original {
    font-size: 12px;
    text-decoration: line-through;
    color: rgba(0, 0, 0, 0.45);
  }
}

.bucket__control {
  margin-top: auto;
  margin-left: auto;
  opacity: 1 !important;

  display: flex;
  flex-direction: row-reverse;
}

.bucket__control--notice {
  width: 100%;
  color: #1f317e;
  padding: 6px 12px;
  background-color: #f4f6ff;
  border: 1px solid #acb6dd;
  border-radius: 37px;
  cursor: pointer;
}

.bucket__control--delete {
  width: 100%;
  margin-top: 13px;
  font-size: 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
}

.container--empty {
  position: relative;
  display: flex;
  flex-direction: row;

  width: 100%;
  justify-content: space-between;
  // opacity: 0.5;

  padding: 16px 24px;
  // border-top: 1px solid lightgray;
  align-items: center;

  .inner {
    align-items: flex-start;
    gap: 0;
  }

  .thumbnail {
    // min-width: 100px;
    width: 80px;
    height: 80px;
    margin-right: 8px;
    cursor: pointer;
  }

  .description {
    flex-direction: column;
    max-width: 210px;
    height: 80px;

    .header__title {
      cursor: pointer;
    }

    .price > .price--selling {
      color: rgba(0, 0, 0, 0.75);
    }
  }

  .bucket__control {
    position: absolute;
    right: 24px;
    bottom: 16px;
    gap: 8px;

    font-size: 12px;
  }

  .bucket__control--notice {
    min-width: fit-content;
  }

  .bucket__control--delete {
    margin-top: 0;
    border: 1px solid #acb6dd;
    border-radius: 37px;
    color: rgba(0, 0, 0, 0.6);

    padding: 6px 12px;
  }
}

.inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}
.inner-number {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 220px;
  justify-content: space-between;
  margin-left: 30px;
}

@media screen and (min-width: 1024px) {
  .container {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding-left: 0;
  }

  .thumbnail {
    height: 82px;
    width: 82px;
    min-width: 82px;
  }

  .description {
    padding: 4px;
    flex-direction: column;
  }

  .header {
    margin-bottom: 0;
  }

  .header__title {
    max-width: 280px;
  }

  .inner {
    align-items: flex-start;
  }

  .price {
    flex-direction: row;
  }

  .bucket__control {
    margin: 0;
    right: 0;
    flex-direction: column;
  }

  .total__price {
    width: 80px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.6);
    text-align: right;
  }
  .inner-number {
    margin-left: 0px;
  }

  .container--empty {
    // padding: 16px 0px 16px 24px;
    border-top: 1px solid lightgray;
    .description {
      height: 80px;
      max-width: none;
    }

    .inner {
      align-items: center;
      gap: 8px;
    }
    .inner:last-child {
      align-items: center;
      // gap: 8px;
      padding-left: calc((124px - 78.59px) / 2);
      min-width: 220px;
      justify-content: space-between;
    }

    .bucket__control {
      position: static;
      font-size: 14px;
    }

    .bucket__control--delete {
      border: none;
    }
  }
}

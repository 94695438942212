.evt-slick-prev,
.evt-slick-next {
  img {
    width: inherit;
    height: inherit;
  }

  @media screen and (max-width: 1023px) {
    display: none;
  }
}

.section {
  padding: 16px;
  .header {
    display: flex;
    align-items: center;
    gap: 8px;

    h2 {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.85);
    }

    small {
      font-size: 14px;
      line-height: 140%;
      color: rgba(0, 0, 0, 0.45);
    }
  }
}

.reivew__container,
.written-article {
  margin-top: 16px;
  display: flex;
  padding: 16px;
  gap: 16px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #f0f0f0;

  & > img {
    border: 1px solid #f5f5f5;
    width: 80px;
    height: 80px;
    cursor: pointer;
  }
}

.image__empty {
  border: 1px solid #f5f5f5;
  width: 80px;
  height: 80px;
}

.review__description {
  display: flex;
  flex-direction: column;

  .review__rating {
    max-width: 192px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h3 {
    cursor: pointer;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.85);
  }
  h4 {
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 16px;
  }
  small {
    font-size: 12px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.85);
  }

  .review__button {
    margin-bottom: 16px;
    padding: 6px 12px;
    width: 100%;
    background: #fafbfe;
    border: 1px solid #e2e5ee;
    border-radius: 37px;

    span {
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: #1f317e;
    }
  }
}

.review__description--written {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .review__description__metadata {
    display: flex;
    flex-direction: column;

    h3 {
      max-height: 40px;
      font-size: 12px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 8px;
      cursor: pointer;
    }

    h4 {
      display: none;
      font-size: 12px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 16px;
    }

    > div {
      display: flex;
      align-items: center;
      img {
        width: 14px;
        height: 14px;
      }
      span {
        margin-left: 4px;
        margin-right: 8px;
        font-size: 12px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.45);
      }
      time {
        font-size: 12px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.45);
      }
    }
  }

  .reivew__description__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-wrap: break-word;

      font-size: 12px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.65);
      margin-bottom: 8px;
    }

    .review__description__edit {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      img {
        width: 80px;
        height: 80px;
        object-fit: contain;
      }

      .review__button--edit {
        align-self: flex-end;
        width: 47px;
        height: 32px;
        border: 1px solid #e2e5ee;
        border-radius: 37px;
        span {
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: rgba(0, 0, 0, 0.65);
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .reivew__container {
    align-items: center;

    & > img {
      margin-right: 16px;
    }
  }

  .review__description {
    width: 100%;
    position: relative;

    .review__rating {
      position: absolute;
      justify-content: center;
      right: 56px;
    }
  }

  .review__description--written {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;

    .review__description__metadata {
      height: 80px;
      align-self: center;
      width: 100%;
      border-right: 1px solid #f5f5f5;
      margin-right: 47px;

      h3 {
        margin-bottom: 0;
      }

      h4 {
        display: block;
        margin-bottom: 16px;
      }
    }

    .reivew__description__content {
      margin-right: 32px;
      p {
        width: 215px;
      }
    }
  }
}

.container--empty {
  // padding: 100px 0;
  width: 100%;
}

.search__popular__container,
.search__history__container {
  padding: 16px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);

  .search--empty {
    padding: 32px 0;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: rgba(0, 0, 0, 0.45);
  }
}

.search__history__container {
  border-bottom: 8px solid #f5f5f5;
  margin-bottom: 16px;
}

.search__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.search__header__delete {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgba(0, 0, 0, 0.65);
  gap: 8px;
  cursor: pointer;
}

.search__items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;

  .search__item {
    display: flex;
    align-items: center;
    gap: 8px;

    color: rgba(0, 0, 0, 0.65);
    padding: 8px;
    border-radius: 4px;
    background-color: #f5f5f5;

    cursor: pointer;
  }
}

@media screen and (min-width: 1024px) {
  .search__history__container,
  .search__popular__container {
    min-width: 560px;
  }

  .search__history__container {
    border: none;
  }

  .search__popular__container {
    .search__items {
      .search__item {
        background-color: #e9edff;
      }
    }
  }
}

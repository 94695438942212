.invitations__banner {
  & {
    flex: none !important;
  }
  .invitations__banner__img {
    height: 120px;
    width: 100%;
    object-fit: cover;
  }
}

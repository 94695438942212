.container {
  // height: 100%;
  flex: 1;
  ul {
    height: 100%;
    .empty_container {
      height: 100%;
      background-color: #fafafa;
      // & > :first-child {
      //   height: 100%;
      // }
      //   background-color: #fafafa;
      //   // padding: 140px 24px;
      //   & > :first-child {
      //     padding: 140px 24px 0;

      //     // margin-top: 140px;
      //   }
    }
  }
}

.wish-list-container {
  padding-bottom: 48px;
}
.wish-list-select-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}
.wish-list-delect-container {
  cursor: pointer;
}

.item {
  position: relative;
  padding: 16px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  border-bottom: 1px solid #f5f5f5;

  .fixed__container {
    position: relative;
    display: flex;
    gap: 3px;
  }

  .thumnail {
    position: relative;
    width: 100px;
    height: 100px;
    border: 1px solid #f5f5f5;

    .product-flag {
      position: absolute;
      top: 0;
      display: flex;
      width: 100%;
      height: 24px;

      .flag {
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 6px;
        width: 32px;
        height: 18px;
        margin-right: 2px;

        background-color: #da3730;

        &.best {
          background-color: #ff9835;
        }

        &.new {
          background-color: #2f4ccb;
        }

        span {
          font-size: 10px;
          line-height: 140%;
          font-weight: 700;
          color: #ffffff;
        }
      }
    }

    img {
      height: 100%;
    }

    .thumnail__soldout {
      width: inherit;
      height: inherit;
      top: 0;
      left: 0;
      position: absolute;
      bottom: 4px;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;

      font-weight: 700;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 8px;
    // height: 100px;

    .description__name {
      font-size: 14px;
      line-height: 140%;
      color: rgba(0, 0, 0, 0.85);
      overflow: hidden;
      height: 40px;
    }

    .description__history {
      font-size: 12px;
      line-height: 18px;
      color: #da3730;
      align-self: flex-start;
    }

    .description__price__current {
      font-weight: 700;
      line-height: 24px;
      color: #da3730;
    }

    .description__price--soldout {
      display: flex;
      flex-direction: column;
      &::before {
        content: 'In stock soon';
        font-size: 12px;
        line-height: 22px;
        color: #da3730;
      }
    }

    .description__price {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      & > div {
        display: flex;
        flex-direction: column;
      }

      .current-price {
        margin-right: 4px;

        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #da3730;
      }

      .description__price__original {
        font-size: 12px;
        line-height: 22px;
        text-decoration-line: line-through;
        color: rgba(0, 0, 0, 0.45);
      }
    }

    .description__star {
      display: none;
    }

    .item__control {
      position: absolute;
      bottom: 21px;
      right: 16px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .container {
    width: 100%;
    & > .items {
      height: auto;
      & .empty_container {
        height: auto;
        // & > :first-child {
        //   height: auto;
        // }

        //     background-color: #fafafa;
        //     // padding: 140px 24px;
        //     & > :first-child {
        //       padding: 140px 24px 200px;

        //       // margin-top: 140px;
        //     }
      }
    }

    .thumnail {
      .thumnail__soldout {
        span {
          position: relative;
          top: -18px;
          font-size: 24px;
          line-height: 32px;
        }
      }

      .product-flag {
        .flag {
          padding: 2px 8px;
          width: 42px;
          height: 24px;
          margin-right: 3px;

          span {
            font-size: 13px;
          }
        }
      }
    }
  }

  .wish-list-select-container {
    margin-bottom: 24px;
  }

  .items {
    & > div {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 4px;
      justify-content: space-between;

      li {
        width: 180px;
      }
    }
    margin-bottom: 56px;
  }

  .item {
    flex-direction: column;
    border-bottom: none;
    margin-bottom: 48px;
    padding: 4px 10px;
    gap: 4px;

    .thumnail {
      width: 160px;
      height: 160px;
      border: none;
    }

    .description {
      width: 160px;

      .description__history {
        display: none;
      }

      .description__price {
        flex-direction: row;
        align-items: center;
        gap: 4px;

        & > div {
          flex-direction: row;
          align-items: baseline;
        }
      }

      .description__star {
        display: flex;
        gap: 4px;
        align-items: center;
        width: 100%;
        color: rgba(0, 0, 0, 0.45);
      }

      .item__control {
        bottom: auto;
        top: 148px;
        right: 14px;
      }
    }

    .fixed__container {
      // position: absolute;
      // top: 4px;
      // left: 10px;
      z-index: 1;
      // padding: 16px 24px;
      display: flex;
      gap: 3px;
    }
  }
}

.register-form {
  padding: 16px !important;
  margin-top: 16px;
  background: #fafafa;
  border: 1px solid #efefef;

  .register-description {
    margin-top: 16px;
    font-size: 12px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.45);
  }

  .register-link {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 16px;
    font-size: 12px;
    line-height: 20px;
    color: #1f317e;
  }
}

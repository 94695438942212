.cart-order-button-container {
  width: 100%;
  // margin-top: 15px;
}

.cart-main-desktop {
  flex: 1;
}

.cart-main-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: stretch;
}
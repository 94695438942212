.category__products {
  width: 100%;
  display: flex;
  padding: 16px;
  flex-direction: column;
}

.category__products__title {
  display: none;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 32px;
}

.category__products__list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 4px;
  & > div {
    width: 160px;
  }
}
.category__empty {
  padding: 0;
  // height: 70vh;
  background-color: #fafafa;
}

@media screen and (min-width: 1024px) {
  .category__products {
    padding: 44px 0 0 64px;
    // min-width: 1080px;
  }

  .category__products__title {
    display: block;
  }

  .category__products__list {
    // justify-content: unset;
    gap: 5px;
    justify-content: space-between;
  }
  .category__empty {
    padding: 44px 0 0 64px;
    height: auto;
    background-color: white;
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;

  .main {
    // min-height: 100vh;
    height: 100%;
    position: relative;
    padding: 16px;
    background: #f5f5f5;
    flex: 1;

    .address__title {
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 16px;
    }
    .address__outer-container {
      overflow-y: auto;
      // height: calc(100vh - 200px);
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    .address__container {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 16px 11px;
      width: 100%;
      height: 94px;
      background: #ffffff;
      border-radius: 8px;
      margin-bottom: 16px;

      label {
        width: 100%;
        gap: 11px;

        input {
          margin: 0 !important;
        }

        & > div {
          flex: 80%;
          span:last-of-type {
            margin-top: 8px;
            height: fit-content;
          }
        }
      }

      .address__container__button {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }

  .address__button {
    width: fit-content;
    margin-left: auto;
    padding: 4px 12px 4px 4px;
    background: #f4f6ff;
    border: 1px solid #acb6dd;
    border-radius: 16px;

    & > img {
      width: 24px;
      height: 24px;
      margin-right: 6px;
    }

    & > span {
      font-size: 12px;
      line-height: 18px;
      color: #1f317e;
    }
  }

  .address__button__container {
    display: flex;
    justify-content: flex-end;
    padding: 8px 16px;
  }

  .address__button__container {
    width: 100%;
    text-align: center;
    background-color: #fff;
    padding: 8px 16px 16px 16px;
    left: 0;
    bottom: 0;
  }

  .button {
    position: fixed;
    bottom: 0px;
    padding: 8px 16px 16px;
    background-color: #fff;
    width: 100%;
  }

  .address__empty-outer-container {
    background-color: #fafafa;
    border-radius: 8px;
    padding: 0 16px;
    height: 100%;
    flex: 1;
  }
}

.form__button {
  display: flex;
  position: fixed;
  bottom: 0px;
  gap: 8px;
  width: 100%;
  background-color: #fff;
  padding: 8px 16px 16px;

  .secondary {
    border: 1px solid #1f317e;
    border-radius: 40px;
    padding: 15px 40px;
    font-weight: 700;
    color: #1f317e;
  }
  & button {
    width: auto;
    flex: 1;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    .main {
      min-height: unset;
      height: fit-content;
      border-radius: 8px;
      background-color: #fafafa;
      margin-bottom: 32px;
      padding: 16px;
      flex: none;

      .address__container {
        .address__container__button {
          flex-direction: row;
        }
      }
    }

    .button {
      position: static;
      width: 200px;
      margin-left: auto;
      text-align: center;

      :first-child {
        padding: 0;
      }
    }
    .form__button {
      position: relative;
      justify-content: flex-end;
      gap: 16px;
      & button {
        width: 200px;
        flex: none;
      }
    }
    .address__empty-outer-container {
      background-color: #fafafa;
      border-radius: 8px;
      height: 100%;
      width: 100%;
      margin: 0 auto;
      button {
        padding: 8px 60px;
      }
    }
  }
}

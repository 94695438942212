.point {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.point__container {
  background-color: #fff;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  height: 100px;
  width: 100%;
  gap: 4px;
  border: 1px solid #f0f0f0;

  .point__container--row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  .point__container--column {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }

  .title {
    font-weight: 700;
    font-size: 13px;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.85);
    // display: flex;
    // justify-content: end;
  }

  .point__amount {
    font-weight: 700;
    font-size: 24px;
    color: #418dff;
    align-self: flex-end;
  }

  .point__description {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
    align-self: flex-end;
  }
}
@media screen and (min-width: 1024px) {
  .point {
    gap: 40px;
  }
  .point__container {
    gap: 8px;
  }
  .point__container--column {
    gap: 8px;
  }
  //   .point__container {
  //     padding: 16px 40px;
  //     .title {
  //       justify-content: flex-start;
  //     }
  //   }
}

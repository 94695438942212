.category__side {
  min-width: 248px;
  padding-top: 32px;
  // padding-left: 16px;
  border-right: 1px solid #f0f0f0;
}

.category__side__head {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}

.category__side__menu {
  margin-bottom: 32px;
  border-bottom: 1px solid #f0f0f0;
}

.category__side__type,
.category__side__item {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.65);

  cursor: pointer;
}

.category__side__type,
.category__side__strong {
  font-weight: 700;
}

.category__side__item {
  margin-left: 8px;
}

.category__side__filter {
  margin-bottom: 32px;

  .category__side__filtergroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .category__filter__item {
      font-size: 14px;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.65);
    }
  }

  padding-right: 16px;
  border-bottom: 1px solid #f0f0f0;
}

.category__side__price {
  margin-bottom: 32px;
  padding-right: 16px;
  border-bottom: 1px solid #f0f0f0;
}

.category-side__items {
  margin-bottom: 16px;
}
.custom_range_container {
  .custom_range_input {
    padding-left: 16px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    span {
      display: flex;
      height: 53px;
      align-items: center;
      margin: 0 8px;
    }
  }
  .custom_range__button {
    margin: 24px 8px 32px;
    width: 100%;
    height: 48px;
    cursor: pointer;
    padding: 8px 20px;
    color: #ffffff;
    background-color: #1f317e;
    border-radius: 40px;
    border: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
  }
}
.range_input_unit {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;

  & input {
    width: 94px;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    height: 48px;
    padding: 14px 16px;
  }

  .range_input_unit_type {
    font-size: 14px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.85);
  }
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .image {
    margin-right: 16px;
  }

  .title {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.45);
  }

  .description {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.85);
  }
}

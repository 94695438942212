.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  gap: 24px;

  margin: 16px 0;
}

.controller {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.controller__term {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}

.pages {
  display: flex;
  flex-direction: row;
}

.pages__item {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.pages__item--active {
  color: #1f317e;
  font-weight: 700;
  border-bottom: 2px solid #1f317e;
}

@import '../../styles/globals.scss';
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fafafa;
  width: 100%;
  padding: 100px 0;
  & .button {
    margin-top: 24px;
    width: fit-content button {
      padding: 8px 20px;
    }
  }
}

.icon {
  margin-bottom: 16px;
  img {
    width: 56px;
    height: 56px;
  }
}

.description {
  margin-bottom: 4px;
  font-size: 14px;
}

.description--sub {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
}

.empty_coupon {
  @include empty_mobile_top_padding(16px);
}
.empty_order {
  @include empty_mobile_top_padding(16px);
}
.empty_review {
  @include empty_mobile_top_padding(16px);
}

@media screen and (min-width: 1024px) {
  .container {
    // padding: 80px 0;
    padding: 0;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }

  .icon {
    width: 64px;
    height: 64px;
  }
  .description {
    font-size: 16px;
  }

  .empty_coupon {
    @include empty_desktop_height(64px);
  }
  .empty_order {
    @include empty_desktop_height(32px);
  }
  .empty_review {
    @include empty_desktop_height(32px);
  }
}

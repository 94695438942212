.div {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

@media screen and (min-width: 1024px) {
  .div {
    & > :not(nav, :first-child) {
      top: 72px;
    }
  }
}

// @media screen and (max-width: 1023px) {
//   .div {
//     padding-bottom: 108px;
//   }
// }

.desktop-header2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: flex-start;
  padding-left: 16px;
  h2 {
    @media screen and (min-width: 1024px) {
      width: 75vw;
    }
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.85);
  }
}

.order-completed-smile-container {
  width: 100%;
  padding: 16px;
  @media screen and (min-width: 1024px) {
    width: 77vw;
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
    gap: 16px;
    background: #f4f6ff;
    border: 1px solid #acb6dd;
    border-radius: 8px;
    img {
      width: 56px;
      height: 56px;
    }
    strong {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }
  }
}

.header {
  width: 100%;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #dee1ec;
  @media screen and (min-width: 1024px) {
    width: 75vw;
    border-radius: 8px 8px 0 0;
  }

  .header__date {
    font-size: 13px;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.85);
  }

  .header__no {
    font-size: 13px;
    line-height: 140%;

    :first-child {
      color: rgba(0, 0, 0, 0.65);
    }

    :last-child {
      color: rgba(0, 0, 0, 0.85);
    }
  }
}

.order-completed-payment-info {
  width: 100%;
  background: #f5f5f5;
  padding: 16px 16px 0 16px;
  @media screen and (min-width: 1024px) {
    display: flex;
    justify-content: center;
    width: 75vw;
    border-radius: 0 0 8px 8px;
    display: flex;
    gap: 24px;
    & > section {
      flex: 50%;
    }
  }
}

.checkout-completed-desktop-buttons {
  margin: 24px 0 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  .secondary,
  .primary {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 80px;
    border-radius: 40px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    height: 48px;
  }
  .secondary {
    border: 1px solid #1f317e;
    font-weight: 700;
    color: #1f317e;
  }
  .primary {
    background: #1f317e;
    color: #ffffff;
  }
}

.checkout-completed-border-separator-container {
  width: 100%;
}

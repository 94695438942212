.div {
  display: flex;
  flex-direction: column;

  .main {
    min-height: 100vh;
    height: 100%;
    background: #ffffff;
  }
}

@media screen and (min-width: 1024px) {
  .div {
    .main {
      min-height: unset;
      height: unset;
      border-radius: 8px;
    }
  }
}

@media screen and (max-width: 1023px) {
  .div {
    .main {
      padding-bottom: 64px;
    }
  }
}

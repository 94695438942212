.radio-label {
  display: flex;
  align-items: center;
  .radio-input {
    margin-top: 0;
    margin-right: 11px;
  }
  .radio-input {
    appearance: none;
    width: 18px;
    height: 18px;
    background: #f5f5f5;
    border: 1px solid #d9d9d9;
    border-radius: 25px;
  }

  .radio-input:checked {
    background-color: #ffffff;
    border: 4px solid #1f317e;
  }
  .radio-text-container {
    display: flex;
    flex-direction: row;
  }
  .radio-text-container--column {
    display: flex;
    flex-direction: column;
  }
  .radio-title {
    font-weight: 700;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    margin-right: 8px;
  }
  .radio-subtitle {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    white-space: pre-line;
    line-height: 140%;
  }

  .radio-subtitle--s {
    font-size: 12px;
  }

  .radio-subtitle--m {
    font-size: 13px;
  }

  .radio-description {
    margin-top: 2px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
  }
}

.container {
  padding: 16px;
  background-color: #f5f5f5;

  > :first-child {
    border-radius: 8px 8px 0 0;
  }

  > :last-child {
    border-radius: 0 0 8px 8px;
  }

  .title__container {
    padding: 8px 0;

    .top-tag {
      font-size: 11px;
      width: fit-content;
      padding: 2px 4px;
      background-color: #1f317e;
      color: #fff;
      border-radius: 2px;
      margin-bottom: 4px;
    }

    .title {
      font-size: 13px;
      white-space: pre-line;
    }

    .created {
      font-size: 12px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.45);
    }
  }

  .description {
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.65);
  }
}

@media screen and (min-width: 1024px) {
  .container {
    background-color: #fafafa;
    border-radius: 8px;

    .title__container {
      padding: 16px 0;

      .title {
        margin-bottom: 10px;
      }
    }
  }
}

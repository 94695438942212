.div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-info-profile {
  width: 100vw;
  height: 114px;
  background: #d2d6eb;
  padding: 16px 16px 42px 16px;
  img:first-child {
    width: 56px !important;
    height: 56px !important;
    border-radius: 28px;
    object-fit: cover;
  }
}

.user-info-point {
  margin-top: -30px;
  padding: 16px;
  width: 92vw;
  height: 56px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  & > div:last-child {
    margin-left: -8px;
    & > div:first-child {
      span {
        font-weight: 700 !important;
      }
    }
    & > div:last-child {
      span {
        font-size: 18px !important;
        line-height: 27px !important;
        color: rgba(0, 0, 0, 0.85) !important;
      }
    }
  }
}

.user-info-refer {
  width: 100vw;
  padding: 16px;
  & > div:last-child {
    & > div:first-child {
      padding-left: 16px;
      span {
        font-weight: 700 !important;
      }
    }
  }
  & + div {
    height: 8px;
  }
}

.user-info-my-orders {
  width: 100vw;
  padding: 8px 16px 12px 16px;
  & > div:last-child {
    & > first-child {
      span {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
  & + div {
    width: 92vw;
  }
}

.account-logout {
  padding: 16px 0;
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
  }
}

.account__post-review,
.account__settings,
.account__language,
.account__policies {
  & + div {
    height: 8px;
  }
}

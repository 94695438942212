.category-side-items {
  margin-bottom: 16px;
}

.category-side-span {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.65);
}

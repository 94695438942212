.section {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  height: 56px;
  gap: 16px;
  cursor: pointer;

  .main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .main__container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .icon {
      margin-right: 16px;
      img {
        width: inherit;
        height: inherit;
      }
    }

    .description {
      display: flex;
      flex-direction: column;

      .description__title {
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.85);
        margin-right: 14px;

        span {
          color: #418dff;
        }
      }

      .description__subtitle {
        font-size: 12px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
      }
    }

    .description__status {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 6px;
      width: fit-content;
      height: 20px;
      background: #fedddb;
      border-radius: 4px;
      font-size: 12px;
      line-height: 20px;
      color: #da3730;
    }

    .main__control {
      display: flex;
      align-items: center;
      gap: 8px;

      .main__detail {
        font-size: 14px;
        line-height: 140%;
        text-align: right;
        max-width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
        color: rgba(0, 0, 0, 0.45);
      }
      .main__count {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        text-align: right;
        color: #418dff;
      }
      img {
        cursor: pointer;
      }
    }
  }
}

.section--current {
  background-color: #4267ff;
  div,
  span {
    color: #fff !important;
  }
}

@media screen and (min-width: 1024px) {
  .section {
    .main {
      .main__control {
        .main__detail {
          max-width: fit-content;
        }
      }
    }
  }
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  // @media screen and (max-width: 1023px) {
  //   padding-bottom: 148px;
  // }

  .article {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    margin-bottom: 16px;
    border-radius: 8px;
    width: 100%;
    overflow: hidden;
    border: 1px solid #f0f0f0;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fafafa;
      padding: 12px 16px;

      .header__no {
        display: flex;
        gap: 4px;
        align-items: center;

        :first-child {
          color: rgba(0, 0, 0, 0.65);
        }
        :last-child {
          color: rgba(0, 0, 0, 0.85);
        }

        span {
          font-size: 13px;
        }
      }

      .header__status {
        padding: 4px 12px;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;

        .header__status--PENDING,
        .header__status--READY {
          color: #418dff;
        }

        .header__status--SHIPPING {
          color: #ff4d4f;
        }

        .header__status--SHIPPED,
        .header__status--CANCELLED {
          color: rgba(0, 0, 0, 0.45);
        }
      }
    }

    .main {
      a {
        width: 100%;
      }

      .main__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;

        .main__thumnail {
          width: 40px;
          height: 40px;
          border: 1px solid #f5f5f5;

          img {
            height: 100%;
            object-fit: cover;
          }
        }

        .main__description {
          white-space: pre-line;
          font-size: 12px;
          line-height: 18px;
          color: rgba(0, 0, 0, 0.85);
          max-width: 182px;
        }

        .main__count {
          font-size: 13px;
          width: 40px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: rgba(0, 0, 0, 0.85);
          background-color: #fafafa;
          border-radius: 50%;
        }

        .main__arrow {
          width: 24px;
          height: 24px;
          img {
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .main__control {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 8px;
        gap: 8px;
        min-height: 48px;

        .main__control--filled {
          background: #f4f6ff;
          color: #1f317e;
        }

        .main__control--unfilled {
          color: rgba(0, 0, 0, 0.65);
        }

        div {
          cursor: pointer;
          border: 1px solid #acb6dd;
          border-radius: 37px;
          font-size: 12px;
          padding: 6px 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 32px;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .section {
    .article {
      .main {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .main__container {
          width: 100%;
          border-right: 1px solid #f5f5f5;

          .main__thumnail {
            width: 64px;
            height: 64px;
            margin-right: 16px;
          }

          .main__description {
            font-size: 14px;
            max-width: 280px;
            width: 100%;
          }
        }

        .main__control {
          height: 100%;
          min-width: 210px;
          flex-direction: column-reverse;
          align-self: center;

          div {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
}

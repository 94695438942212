.div {
  z-index: 10;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  gap: 4px;
}

.mobile-menu-bg-container {
  z-index: 10000;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0px;
  top: 0px;
  overflow-y: scroll;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));

  .mobile-menu-nav-container {
    overflow-y: auto;
    position: fixed;
    top: 0;
    left: 0px;
    bottom: 0px;
    width: 320px;
    height: 100vh;
    background-color: white;
    @keyframes slideToRight {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(0);
      }
    }
    &.show {
      animation: slideToRight 0.5s ease-in-out;
    }
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 16px 24px;

      h1 {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.03em;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.85);
      }

      img {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
    ul {
      display: flex;
      flex-direction: column;

      a {
        &:hover {
          background: #4267ff;
          li {
            & > div:first-child {
              span {
                color: white;
              }
            }
          }
        }
        border-bottom: 1px solid #f0f0f0;
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 12px 24px;
          & > div:first-child {
            display: flex;
            align-items: center;
            img {
              width: 24px;
              height: 24px;
            }
            span {
              margin-left: 16px;

              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              /* identical to box height */

              text-transform: uppercase;

              /* Character/Title .85 */

              color: rgba(0, 0, 0, 0.85);
            }
          }
          & > div:last-child {
            img {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }
}

.container {
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #f0f0f0;

    & > div {
      max-width: 752px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    // height: 60px;
    padding: 0 16px;
    box-sizing: content-box;

    .arrow {
      transition: transform 0.2s ease;
    }
  }

  .title--selected {
    color: #1f317e;
    background-color: #f2f5ff;
    border-bottom: 1px solid #4c69e4;

    .arrow {
      transform: rotate(-180deg);
    }
  }

  .description {
    padding: 0 16px;
    background-color: #fafafa;

    overflow: hidden;
    max-height: 0;
    transition: 0.2s;

    white-space: pre-line;
  }

  .description--selected {
    padding: 16px;
    max-height: fit-content;
  }
}

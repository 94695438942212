.overlay-container {
  z-index: 10009;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  & > div {
    & > :not(nav, footer) {
      min-height: 90vh;
      flex: none;
      width: 100%;
    }
  }
}

.footer-add-top-padding {
  footer {
    padding-top: 40px;
  }
}

.footer-add-top-remove-bottom-padding {
  footer {
    padding-bottom: 0px;
    padding-top: 40px;
    & > div {
      padding-bottom: 40px;
    }
  }
}

.order-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 100%;
  height: 48px;
  background: #1f317e;
  border-radius: 35px;

  & > div:first-child {
    position: relative;
    width: inherit;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    .price-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px 16px;
      position: relative;
      left: -4px;
      min-width: 75px;
      max-width: 100px;
      height: 40px;
      background: white;
      border-radius: 40px;
      span {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #1f317e;
      }
    }
    & > span {
      display: block;
      flex: 1;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: white;
    }
  }
}
.guide-message-container {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: -52px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 36px;
  width: 87.5%;
  height: 36px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 27px;
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: #ffffff;
    b {
      color: #fceb4e;
    }
  }
}

.triangle {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: -16px;
  width: 0;
  height: 0;
  border-bottom: 8px solid transparent;
  border-top: 8px solid rgba(0, 0, 0, 0.8);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}

@mixin border-separator($margin-top, $width, $height) {
  background-color: #f5f5f5;
  margin-top: $margin-top;
  width: $width;
  height: $height;
}

@mixin desktop_width {
  min-width: $desktop_min_width;
  width: 100%;
  max-width: $desktop_max_width;
  padding: 0 20px;
}

@mixin empty_mobile_top_padding($padding-top) {
  padding-top: calc(100px - $padding-top);
}

@mixin empty_desktop_height($padding-vertical) {
  padding-top: 0px;
  height: calc(400px - $padding-vertical);
}

.container {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.input__container--gap {
  display: flex;
  gap: 8px;
}
.input__container {
  display: flex;
}

.description {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 16px;

  .description__alert {
    color: #da3730;
  }
}

.form__button {
  display: flex;
  gap: 8px;
  margin: 16px 0 32px;

  .secondary {
    border: 1px solid #1f317e;
    border-radius: 40px;
    padding: 15px 40px;
    font-weight: 700;
    color: #1f317e;
  }
  & button {
    width: auto;
    flex: 1;
  }
}

.form__checkbox {
  margin-bottom: 16px;
}

@media screen and (min-width: 1024px) {
  .container {
    border: 16px solid #fafafa;
  }

  .form__button {
    width: fit-content;
    margin-left: auto;
    margin-bottom: 16px;
    .secondary {
      min-width: 110px;
      padding: 0;
      font-weight: 700;
      color: #1f317e;
    }
  }
}

.checkout-desktop-save {
  display: flex;
  justify-content: flex-end;
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 40px;
    background: #1f317e;
    border-radius: 40px;

    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }
}

.form-label {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}

.label-container {
  display: block;
  margin-bottom: 8px;
}
